export const en = {
  common: {
    hello: 'hello there',
    'welcome.to.kumba': 'Welcome to Kumba',
    'already.have.account?': 'Already have account?',
    'sign.in': 'Sign In',
    'sign.up.for.free': 'Sign up for free.',
    'phone.input.placeholder': 'Phone number',
    'phone.input.invalid_number': 'Please enter a valid number',
    'pet.name.input.placeholder': 'Name*',
    'pet.type.input.placeholder': 'Species',
    'pet.gender.input.placeholder': 'Sex*',
    'pet.chip.number.input.placeholder': 'Microchip Number',
    'pet.medical.alerts.input.placeholder': 'Medical Alerts',
    'pet.breed.input.placeholder': 'Breed',
    'pet.fur.color.placeholder': 'Choose Fur Color',
    'pet.food.type.input.placeholder': 'Food Type',
    'food.type.placeholder': "Pet's food type or brand",
    'pet.weight.input.placeholder': 'Weight',
    'pet.birthday.input.placeholder': 'Birthday*',
    'pet.avatar.input.placeholder': 'Upload pet image',
    'pet.behavioral.notes.input.placeholder': 'Behavioral Notes',
    'describe.pet': 'Behavioral Notes',
    'pet.sterilized.input.placeholder': 'Spayed/Neutered',
    'pet.external_id.title': 'PIM Reference ID:',
    'pet.link.clipboard.message': 'Link has been copied to the clipboard',
    'pet.blank.external_id': 'NaN',
    'go.back.button': 'Go back',
    'advanced.information': 'Additional',
    allergy: 'Allergy',
    'add.allergy': 'Add Allergy',
    continue: 'continue',
    'get.code': 'Get Code',
    'lets.get.started': "Let's Get Started",
    'add.your.pet': 'Add Your Pet',
    'fillout.pet.information.registration': 'Please fill out as much information as possible',
    'do.you.want.to.add.more.pets': 'Do you want to add another pet?',
    'are.you.sure.remove.pet': 'Are you sure you want to remove this pet?',
    remove: 'Remove',
    yes: 'Yes',
    no: 'No',
    'yes.or.no': 'Yes/No',
    'phone.input.search.placeholder': 'Search for countries',
    'phone.input.placeholder_enter_number': 'Enter number',
    'enter.your.phone.number':
      'Enter your phone number to receive a verification code for account acces',
    'dont.have.account?': "Don't have an account?",
    'resend.new.code': 'Resend a new Code',
    resend: 'Resend',
    'verify.code': 'Verify code',
    'code.verification': 'Code Verification',
    'enter.6.digit.code': 'Please enter the 6-digit code that was sent to',
    'get.started': "Let's get started!",
    'fill.personal.info':
      'Please fill in your details (address is required for the default start/end of your routes)',
    'verification.code.sent':
      'Verification code has been sent to your phone. Valid for next one hour',
    firstName: 'First name',
    lastName: 'Last name',
    'lastName.required': 'Last name*',
    'phone.number': 'Phone number*',
    'phone.number.not.required': 'Phone number',
    email: 'Email',
    'email.required': 'Email*',
    'email.tooltip.text': 'Email address is needed to verify your account',
    floor: 'Floor',
    comment: 'Additional address instructions',
    country: 'Country',
    city: 'City',
    address: 'Route start/end address*',
    complete_profile_form: {
      working_area: 'Address of Clinic\\Home'
    },
    'postal.code': 'Postal code',
    'edit.pet': 'Edit Pet',
    'remove.pet': 'Remove Pet',
    'here.is.list.of.your.pets': "You can add more pets or edit your pet's information here",
    'my.pets': 'My Pets',
    'add.new.pet': 'Add New Pet',
    cancel: 'Cancel',
    'edit.info.of.your.pet': "Edit the Pet's info",
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    calendar: 'Calendar',
    'your.conversations': 'Your Conversations',
    'search.placeholder': 'Search ...',
    search: 'Search',
    pets: 'Pets',
    home: 'Home',
    chat: 'Chat',
    'chat.info': 'Shared media',
    finance: 'Finance',
    settings: 'Settings',
    help: 'Help',
    profile: 'Profile',
    'log.out': 'Log Out',
    'help.center': 'Help center',
    'search.for': 'Search for',
    conversations: 'Conversations',
    'write.your.message': 'Write your message...',
    'read.only': 'Read only',
    'read.only.description': 'To send a message, you need activate the plan for this caregiver',
    'search.in.conversations': 'Search in conversations',
    'no.media': 'No shared media',
    'no.files': 'No shared files',
    'shared.media': 'Shared media ({{count}})',
    'shared.files': 'Shared files ({{count}})',
    'no.messages.yet': 'No clients to chat with yet',
    'choose.subscription.plan.and.chat.with.your.vet':
      "After scheduling an appointment with client you'll be able to chat with him directly from here",
    'choose.plan': 'Choose plan',
    'order.service': 'Become a member!',
    'select.a.plan.than.works.for.you': 'Join as a member or schedule a One-time visit',
    'yearly.membership': 'Membership',
    'one.time.visit': 'One Time Visit',
    'access.to.all.our.services': 'All vaccines, preventive care & check-ups',
    'veterinarian.service': 'Online consultation with your caregiver',
    'free.consultation': 'Clinic referral',
    'order.now': 'Order Now',
    'not.find.slot': 'looking for another date?',
    'get.discount': 'Get <1>{{getDiscount}}</1> by adding more pet(s) to your purchase',
    congratulation: 'Oh Yeah!',
    'select.more.pets': 'Select more pets',
    'continue.anyway': 'Continue anyway',
    'select.pets': 'Select Pet/Pets',
    'choose.your.pets.to.continue': 'Choose your pet/pets to continue',
    'membership.details': 'Membership details',
    'check.details.of.membership.before.continue': 'Check details of membership before continue',
    basic: 'Basic',
    change: 'Change',
    'selected.plan': 'Selected Plan',
    'total.price': 'Total price',
    price: 'Price',
    'you.ve.got': "You've got",
    discount: 'Discount',
    'info.text':
      'We recommend this plan according to your pet`s type, age and vaccination history You can change the selected plan by clicking the edit button.',
    checkout: 'Checkout',
    'personal.info': 'Personal information',
    'keep.me.update.on.news.and.exclusive.offers': 'Keep me updated on news and exclusive offers',
    'add.pets.from.list': 'Add pets from list',
    'selected.pets': 'Selected pets',
    vet: 'Caregiver',
    vets: 'Caregivers',
    'checkout.details': 'Payment',
    'visit.details': 'Visit summary',
    name: 'Name',
    type: 'Species',
    gender: 'Sex',
    'gender.required': 'Sex',
    age: 'Age',
    'select.a.pet': 'Select your pet',
    'select.a.vet': 'Select a caregiver',
    'choose.date.of.visit': 'Choose date of visit',
    'time.slots.for.date': 'Choose time slot for',
    save: 'Save',
    back: 'Back',
    'choose.vet.and.a.time': 'Select a caregiver and choose a time slot that works for you',
    'choose.vet.and.continue':
      'Calendar view is also available for each caregiver (see caregiver card)',
    'select.all': 'Select all',
    'deselect.all': 'Deselect all',
    'fully.vaccinated': 'Did your pet get vaccinated more than once?*',
    'choose.plan.for.specific.pet':
      'If {{petName}} is a puppy/kitten please add the puppy extension',
    'code.sent': 'Code sent',
    'invalid.file': 'Invalid File',
    'you.can.only.upload.images': 'You can only upload images',
    'image.must.be.smaller.than.10.mb': 'Image must be smaller than 10 mb',
    'you.will.be.able.to.resend.code.in': 'You will be able to resend code in',
    'please.provide.right.code': 'This code is wrong. Please try again',
    notifications: 'Notifications',
    'mark.all.as.read': 'Mark all as read',
    today: 'Today',
    instructions: 'Summary',
    earlier: 'Earlier',
    timeline: 'Timeline',
    'filter.by': 'Filter by',
    'all.years': 'All years',
    'hey.you.are.here.now': 'Appointments history',
    'new.pet': 'New Pet',
    'book.extra.visit': 'Book extra visit',
    subscribe: 'Subscribe',
    'no.meetings.yet': 'No appointments yet',
    'choose.subscription.plan.or.schedule.a.visit':
      'Book your first appointment, and track your activity from here',
    cta: 'CTA',
    'extra.costs': 'Additional costs',
    with: 'With',
    missed: 'Missed',
    'vet.info': 'Caregiver Info',
    biography: 'Biography',
    'weekly.time.slots': 'Weekly time slots',
    apply: 'Apply',
    'choose.time.slot.first': 'Please choose a time slot to continue',
    'covered.by.wellness.plan': 'Covered by wellness plan',
    'extra.visit': 'Extra visit',
    'reschedule.event': 'Reschedule',
    'user.comment': 'Comments for your caregiver',
    'user.address': 'Address',
    'visit.information': 'Visit information',
    vaccines: 'Vaccines',
    'welcome.back': 'Welcome back',
    'medications.products.given': 'Medications / products given',
    files: 'Files',
    description: 'Description',
    'given.to': 'Given to',
    qty: 'Qty',
    total: 'Total',
    'download.all': 'Download all',
    'download.receipt': 'Download receipt',
    'you.dont.have.upcoming.meetings': "You don't have upcoming meetings",
    'you.dont.have.past.meetings': "You don't have past meetings",
    'download.medical.record': 'Download medical record',
    'wellness.plan': 'Wellnes plan',
    'profile.completed.on': 'Profile completion:',
    weight: 'Weight',
    breed: 'Breed',
    'chip.number': 'Chip number',
    'copied.to.clipboard': 'Copied to clipboard',
    'spray.neuter': 'Spayed / Neutered',
    'food.type': 'Food type',
    'medical.alerts': 'Medical alerts',
    allergies: 'Allergies',
    note: 'Behavioral Notes',
    'book.a.visit': 'Book a visit',
    'profile.details': 'Profile details',
    'deleting.your.pet.will.remove.all.pets.data.permanently.this.cannot.be.undone':
      'Deleting your pet will delete all of the pet`s data permanently, this cannot be undone.',
    delete: 'Delete',
    confirm: 'Confirm',
    'payment.method': 'Payment method',
    dr: 'Dr',
    'client.name': 'Client name',
    'medical.file.for': 'Medical file for',
    veterinarian: 'Caregiver',
    summary: 'Summary',
    'kumba.application.ltd': 'Kumba application ltd',
    date: 'Date',
    'choose.pet': 'Choose pet',
    'you.will.get': "Upon checkout you'll get",
    'on.second.pet.membership': 'for purchasing it for multiple pets',
    'selected.vet': 'Caregiver',
    'by.creating.account': 'By creating an account, you agree with Kumba’s the',
    'terms.of.service': 'Terms of Service',
    and: 'and',
    'privacy.policy': 'Privacy Policy',
    visit: 'visit',
    'we.cant.give.you.service.for.now': 'We are sorry',
    'address.that.you.put.is.not.in.our.working.area':
      'There is not available Caregiver in your area',
    'we.are.growing.fast.and.will.become.available.in.more.regions.soon':
      'We’re growing fast and will become available in more regions soon',
    street: 'Street',
    'building.number': 'Building number',
    'zip.code.for.us': 'Zip code (for US only)',
    close: 'Close',
    ok: 'OK',
    'home.visit': 'House call',
    'one.time.payment': 'one time payment',
    'additional.one.time.payment': 'Additional one time payment',
    'puppies.need.procedures': 'Puppies need more procedures and more attention from caregiver.',
    'kittens.need.procedures': 'Kittens need more procedures and more attention from caregiver.',
    'additional.payment.info': 'This is additional payment that you will pay only one time',
    processing: 'Processing',
    completed: 'Completed',
    failed: 'Failed. Try again',
    new: 'new',
    'card.number': 'Card number',
    cvc: 'cvv',
    'expiry.date.mm.yy': 'Expiry date MM/YY',
    year: 'Year',
    'years.of.experience': '{{count}} years of experience',
    'the.appointments.rescheduled.later': 'The appointments can be rescheduled later',
    'price.per.month': 'Price per month',
    month: 'month',
    'per.month': 'm',
    'pay.now': 'Pay now',
    instuctions: 'Summary',
    done: 'Done',
    'add.comment.optional': 'Add comment (optional)',
    'skip.payments': 'Skip Payments',
    'format.not.supported': 'Format not supported',
    'you.can.leave.comment': 'Type your comment',
    required: 'Required',
    clients: 'Clients',
    routes: 'Routes',
    'home.address': 'Home Address',
    'start.point': 'Start Point',
    'end.point': 'End Point',
    upload: 'Upload',
    'first.name': 'First Name',
    'first.name.required': 'First name*',
    last_name: 'Last Name',
    social_number: 'ID Number',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    occupied: 'Occupied',
    limit_reached: 'Limit reached',
    'license.number': 'License Number',
    'main.commute.method': 'Default Commute Method',
    car: 'Car',
    bike: 'Bike',
    bicycle: 'Bicycle',
    walk: 'Walk',
    experience: 'Experience',
    'experience.years': 'Years of experience',
    mobile_number: 'Mobile Number',
    select_timezone: 'Select a Timezone',
    building_number: 'Building number',
    zipcode: 'Zip code (for US only)',
    bank_code: 'Bank Code',
    bank_branch: 'Bank Branch',
    swift: 'SWIFT BIC',
    bank_name: 'Bank Name',
    bank_account: 'Bank Account Number',
    create_vendor: 'Create a vendor',
    payment_provider: 'Payment Provider',
    seller_code: 'Sellet Inc Code',
    social_issue_date: 'ID issue Date',
    bank_account_class: 'Bank Account Class',
    bank_account_type: 'Bank Account Type',
    account_id: 'Account ID',
    bank_state: 'Bank State',
    bank_city: 'Bank City',
    'birth.date': 'Date of birth',
    'birth.date.required': 'Date of birth',
    'field.is.required': 'The field is required',
    male: 'Male',
    female: 'Female',
    'check.address': 'Check address fields',
    'edit.address': 'Edit address',
    submit: 'Submit',
    confirm_delete_timeslot: 'Are you sure to delete this time slot?',
    'financial-data': 'Financial Data',
    inventory_default_setup: 'Inventory default setup',
    'non-working_areas': 'Non-Working Areas',
    'working.days': 'Working Days',
    cards: 'Cards',
    active_subscriptions: 'Active subscriptions',
    'failed.to.update': 'Failed to update',
    'failed.to.create': 'Failed to create',
    all_info_will_be_lost: 'All the information will be lost!',
    leave_the_page_question: 'Are you sure you want to leave the page?',
    leave: 'Leave',
    'update.vet': 'Profile',
    'profile.picture': 'Profile picture',
    'professional.information': 'Professional information',
    languages: 'Languages',
    'info.will.be.visible': 'Visible to your clients',
    'biography.placeholder': 'What do you want your clients to know about you?',
    'fields.visible.to.clients': 'marked field is visible to client',
    'download.app.to.manage.routes':
      'Download the mobile app to manage your routes and appointments',
    'all.fields.visible.to.clients': 'All the fields below are visible to client',
    'fill.pet.info': 'Please fillout the information about the pet',
    'allergies.input.placeholder': 'Type allergy, press enter to add one',
    kg: 'kg',
    lb: 'lb',
    'next.appointment.date': 'Next appointment date',
    'are.you.sure.want.to': 'Are you sure you want to',
    'cancel.appointment': 'Cancel appointment',
    'cancel.the.appointment': 'Cancel the appointment?',
    'add.timeslot': 'Add time slot',
    sign_in: {
      title: 'Welcome to Kumba',
      sub_title: 'I am a:',
      caregiver: 'Caregiver',
      clinic_admin: 'Clinic Admin',
      pet_owner: 'Pet Owner',
      CTA: 'Continue'
    },
    timeslot_recommendations: {
      recommendations: 'Recommendations',
      make_short:
        'Time slots of around 4 hours are recommended to best balance client schedules and route efficiency.',
      morning_or_evening:
        'Many chose to do a time slot in the morning and a time slot in the afternoon (with a break in between)'
    },
    vet_routes: {
      calendar_link: 'Your booking page link',
      calendar_tooltip:
        'Send clients this link so they can book appointments online. You can also embed it on your website, add it to your email signature, or post it on social media. \n(click on the button to the right to copy it)\n',
      copy_link: 'Copy Link',
      passed_your_plan: 'You have passed your plan limit',
      view_schedule: ' To view your schedule',
      upgrade_your_plan: 'upgrade your plan',
      upgrade_plan: 'Upgrade plan',
      copy: 'Copy'
    },
    'appointment.has.been.scheduled.with': 'Appointment has been scheduled with',
    'time.slot': 'Time Slot',
    'go.to.home.page': 'Go to home page',
    'appointment.details.has.been.sent.to.your.email':
      'Appointment details has been sent to your email',
    'client.details': 'Client details',
    'client.has.no.pets': "Client doesn't have pets",
    'cancel.appointments.before.removing':
      "Please cancel all pet's appointments before removing it",
    select: 'Select',
    'male.or.female': 'Male/Female',
    available: 'Available',
    'vet.address.tooltip.info':
      'This address will be used as the default start/end points for route calculations. This is most likely your home or clinic address. You will be able to edit the starting address and ending address for each route from the app.\n',
    'edit.pet.info': 'Edit pet info',
    'powered.by': 'Powered by',
    'schedule.an.appointment.with': 'Schedule an appointment with',
    'already.have.an.account': 'Already have an account',
    'address.and.date.time': 'Address and date/time',
    'patient.details': 'Patient details',
    'personal.details': 'Personal details',
    next: 'Next',
    finish: 'Finish',
    inform_client_by_email: 'Inform Client by Email',
    'enter.your.address': 'Enter the visit address',
    'check.your.address': 'Enter the visit address',
    'additional.details': 'Additional details',
    'property.type': 'Location type',
    'private.house': 'House',
    apartment: 'Apartment',
    flat: 'Appartment/unit',
    'special.instructions': 'Special Instructions',
    'instruction.text':
      'When you enter main door go to the left to elevator and then type this code :987600',
    choose_another_vet_modal: {
      'choose.another.vet': 'Choose another caregiver',
      'choose.vet': 'Choose caregiver',
      'please.fill.information.about.pet': 'Please fill out information about your pet'
    },
    scheduling_stepper: {
      vet_first_step_title: 'Client address',
      vet_third_step_title: 'Client info and contact',
      vet_address_title: 'Client address',
      vet_special_instructions_text: 'e.g. Parking options, special instructions',
      vet_personal_step_title: 'Client info and contact'
    },
    appointments: 'Appointments',
    houseHolds: 'Households',
    houseHold: 'Household',
    'name.the.timeslot': 'Name the time slot (e.g. geographical region, time in day)',
    'zip.code': 'Zip-code supports US only',
    timeslots: 'Time Slots',
    'vet.timeslots.exclude.success': 'Time slot deleted successfully',
    'vet.timeslots.exclude.error':
      "Time slot cannot be deleted. Please reschedule all it's appointments first",
    'are.you.sure.remove.timeslot': 'Are you sure you want to remove this timeslot?',
    'clone.timeslot': 'Duplicate timeslot',
    edit: 'Edit',
    'end.time': 'End Time',
    'start.time': 'Start Time',
    'working.area': 'Working area',
    'choose.date': 'Select date',
    'the.field.is.required': 'The field is required',
    'about.me': 'About',
    'choose.another.vet': 'Choose another caregiver',
    'see.less': 'See less',
    'see.more': 'See more',
    'short.biography': 'About me',
    choose: 'Choose',
    'comment.text': 'Example: Gated community, gate code is 1920',
    'choose.a.day': 'Choose a day',
    'time.value.text':
      '* We value your time. You will receive a more accurate time a day before the visit.',
    'choose.day.to.see.available.timeslots': 'Choose a day to see available timeslots',
    patient_details: {
      add_info: 'Enter the pets Info',
      add_info_vet: 'Appointment details',
      select_patients: 'Select the patients that are relevant for the appointment:',
      'pet.type': 'Species',
      'pet.name': 'Name',
      'add.a.pet': 'Add a Pet',
      'add.pet': 'Add Pet',
      'pets.condition':
        "Additional details about your pet's condition that will help your caregiver be prepared",
      'condition.label': "Tell us more about your pet's condition",
      vet_pets_condition: 'Comments about patients status,complaints',
      vet_condition_label: 'Comments',
      vet_concern_label: 'Appointment reason',
      'concern.label': "What's the concern",
      'please.fill.fields': 'Please fill all the required fields first',
      select: 'Select',
      'choose.pets': 'Select pets*',
      first_time_visit_title: 'First time visit for one or more pets',
      first_time_visit_tooltip: 'This is a first time visit for some or all of the selected pets',
      recalculate_duration: "Recalculate the appointment's duration"
    },
    personal_details: {
      'add.information.about.yourself': 'Enter your contact info',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      'phone.number': 'Phone number'
    },
    verify_code: {
      title: 'Please verify your Phone number',
      description: 'Enter the 6-digit code that we have sent you via the phone number'
    },
    wizard_tour: {
      'welcome.to': 'Welcome to the',
      'tutorial.guide': 'tutorial guide.',
      'lets.get.started': "Let's get started",
      'fill.your.profile': 'Fill your profile',
      'open.time.slots': 'Open time slots',
      'share.your.booking.page.link': 'Share your booking page link',
      'download.the.mobile.app': 'Download the mobile app',
      'want.more.info': 'Want more info?',
      'learn.more': 'Learn more',
      'step1.descriptions': {
        1: 'Fill out your personal and professional information.',
        2: 'Some of this information will be visible to your clients.',
        3: ''
      },
      'step2.descriptions': {
        1: 'Go to the “Schedule” tab, click on “Add time slot”, and add working time slots by selecting the zip code, date, start time, and end time.',
        2: 'You can easily name, clone, edit, and delete time slots',
        3: 'Time slots of around 4 hours are recommended to best balance client flexibility and route efficiency.'
      },
      'step3.descriptions': {
        1: 'Send your personal scheduling link to clients, so they can book appointments online. This can be done through email, text, chat, social media, in your website or other communication channels.',
        2: 'Contact us if you need help.',
        3: ''
      },
      'step4.descriptions': {
        1: 'Download the mobile app to manage your schedule, view/edit/start your optimized routes, chat with clients, and access client information and patient records. ',
        2: '',
        3: ''
      }
    },
    routes_page: {
      select_zip_codes: 'Select Zip-codes',
      by_zip_code: 'By Zip-code',
      select_on_map: 'Select on map'
    },
    'edit.timeslot.confirm':
      'Some Appointments are already scheduled for this time slot. Please verify your changes fit these appointments before saving',
    'no.slots.error':
      'Please contact us directly by phone, text, or email to schedule for your location',
    'how.it.works': 'How it works',
    'too.many.symbols': 'Too many symbols',
    'status.text':
      'Available: Clients will be able to book new appointments. \n\n Occupied: Clients will not be able to book new appointments.',
    'wrong.file.type': 'Wrong file type',
    'vet.booking.error':
      'To schedule an appointment, please log out and create a pet-owner account',
    error: {
      'user.already.exists': 'email or phone number already exists',
      'email.already.exists': 'This email address already exists',
      'phone.already.exists': 'This phone number already exists',
      polygon: 'Please draw polygon',
      'address.dropdown': 'Enter a complete address (including street and building number) and select it from the dropdown',
      'input.phone.required': 'Phone number is required'
    },
    'polygon.name': 'Polygon Name',
    latitude: 'Latitude',
    longitude: 'Longitude',
    'please.select.timeslot': 'Please select a timeslot',
    'this.pet.has.appointments': 'to delete this pet, please cancel any future appointment',
    customers: {
      'search.placeholder': 'Search clients',
      'customer.placeholder': 'Search by name',
      'pets.placeholder': 'Search by patient',
      'client.name': 'Client Name',
      'pets.name': 'Patient Name',
      'contact.info': 'Contact Info',
      'pet.amount': 'Number of patients',
      'next.appointment.date': 'Next Appointment Date',
      'next.no-appointments': 'No appointments',
      'next.estimated-short': 'est.',
      'estimated.next.appointment': 'Estimated next appointment',
      'sync-status.filter.label': 'Sync status',
      'sync-status.filter.menu': {
        synced: 'Synced clients',
        error: 'Sync errors',
        all: 'All clients'
      }
    },
    'client-modal': {
      'next-appointment-column': {
        title: 'Next Appointment Date',
        'no-appointments': 'No appointments',
        'estimated-appt-short': 'est.'
      }
    },
    vet_profile: {
      'commute.method.tooltip':
        'This will be used as default commute method when calculating your routes',
      'main.commute.method.required': 'Default Commute Method*',
      'default.routes': 'Default address for routes',
      short_biography: 'Short Biography'
    },
    route: {
      lock: 'Lock Route',
      unlock: 'Unlock Route',
      lock_message: 'Lock has changed successfully',
      lock_warning: 'This time slot still accepts new appointments so ETAs are not final.',
      warn_title: 'You’re going to unlock the route',
      desc: 'The route for this day has already been calculated. It is recommended to keep it locked for new appointments for the following reasons:',
      desc_opt_1: 'You might see appointment ETAs that are not final',
      desc_opt_2:
        'the client might see a very long time-window when looking for time for an appointment'
    },
    tooltip: {
      'rescheduling.error':
        'The appointment is too soon and therefore cannot be rescheduled now. please try to contact your caregiver',
      'canceling.error':
        'The appointment is too soon and therefore cannot be cancelled now. please try to contact your caregiver'
    },
    url: {
      schedule: 'Schedule',
      settings: 'Settings',
      app_manager: 'Appt manager',
      clinic_schedule: 'Schedule',
      clinic_caregivers: 'Caregivers',
      clinic_clients: 'Clients',
      dashboard: 'Dashboard',
      integrations: 'Integrations',
      service_areas: 'Service areas',
      plans_usage: 'Plans & Usage',
      services: 'Supported Services',
      time_slots: 'Time slots'
    },
    preferences: {
      update: 'Update',
      delete: 'Delete',
      save: 'Save',
      cancel: 'Cancel',
      add: 'Add',
      new_concern: 'New Concern',
      update_reminders: 'Update Reminders',
      update_communications: 'Update Communications',
      another_preferences: 'Another Preferences',
      concerns: 'Concerns',
      customer_notifications_title: 'Customer notifications and messages',
      customer_notifications_description:
        'These notifications are sent out to the customer automatically. Click on the notification template to edit the content.',
      route_title: 'Route default settings',
      route_description:
        'These settings will be used by default when creating your routes and appointments',
      services_title: 'Supported services',
      services_description: 'These settings will be used by default, adjusted by clinic',
      services_vetAdmin_description:
        "Manage your clinic's services by setting their duration, availability, and online booking options. You can also disable or remove services as needed",
      reminders_title: 'Notifications and Messages',
      general: 'General',
      optional_parameters: 'Optional Parameters:'
    },
    appointments_header: {
      reorder: 'Reorder',
      new_appointment: 'New Appointment',
      reorder_success: 'Successfully reordered'
    },
    not_found: {
      title: 'Oops!',
      error_message: 'Page not found',
      instruction: 'Press below and we’ll smell your way back home',
      button_text: 'Go to home page'
    },
    note_modal: {
      title: 'Please note',
      text: 'For urgent medical issues, please contact your caregiver directly.',
      button: 'OK, Got it'
    },
    new_appointment: {
      select_client: 'Select/Add Client',
      new_client: 'New Client',
      search_placeholder: 'Search for a client',
      empty_state_title: 'No clients',
      empty_state_description: 'Share your scheduling link so owners can sign up',
      address: 'Address:',
      success_message: 'Appointment has been created successfully',
      reschedule_appointment: 'Reschedule Appointment',
      cancel_appointment: 'Cancel Appointment',
      view_duration: 'View/edit Duration',
      edit_eta: 'Edit ETA',
      add_patients_title: 'Edit Patients',
      add_patients_menu_item: 'Edit Patients',
      set_duration_title: 'Set appointment duration',
      set_duration_text:
        'Changing the appointment duration\nis affecting the <strong>ETA</strong> of the next appointments',
      input_suffix: 'Min',
      cancel_appointment_title: 'Cancel Appointment',
      cancel_appointment_text: 'Are you sure you want to continue?',
      reschedule_appointment_title: 'Reschedule Appointment',
      calendar_title: 'Choose date',
      time_slots_for_date: 'Available timeslots:',
      filters: 'Filters',
      show_full_ts: 'Show full/locked timeslots',
      show_ts_in_areas: 'Show timeslots in other areas',
      duration_success: 'Duration has been updated successfully',
      cancel_success: 'Appointment has been cancelled',
      eta_success: "Appointment's ETA has been saved",
      patients_edit_success: "Appointment's patients list has been saved",
      reschedule_success: 'Appointment has been rescheduled successfully',
      yes_cancel: 'Yes, Cancel appointment',
      add_address: 'Add address'
    },
    edit_clients_modal: {
      title: "Edit Client's Address"
    },
    timeslot_modal: {
      clients_limit: 'Use default max client limit',
      default_points: 'Use default Start/End Points',
      is_single_timeslot: 'Unify with other time slots in the same day',
      working_area_lbl: 'Service Areas',
      add_working_area_btn: 'Create service area',
      select_wa_placeholder: 'Select service areas'
    },
    eta_tooltip: {
      title: 'View ETA',
      duration: 'Duration',
      current_eta: 'Current ETA',
      min: 'Min',
      bookable: 'Bookable',
      non_bookable: 'Non-Bookable'
    },
    address_step: {
      comment_title_for_vet: 'Special instructions'
    },
    timeslots_mobile_item: {
      hours: 'Hours',
      working_area: 'Working Areas'
    },
    new_service_modal: {
      hours: 'Hours',
      minutes: 'Minutes',
      title: 'Service Details',
      service_name_label: 'Enter service name',
      service_name_placeholder: 'E.g Wellness, End-of-life...',
      service_duration_label: 'Set duration',
      service_hours_placeholder: '0 hours',
      service_minutes_placeholder: '0 minutes',
      service_availability_label: 'Set availability',
      available: 'Available',
      not_available: 'Not Available',
      service_visibility_label: 'Visibility',
      service_visibility_placeholder: 'Bookable by customer',
      button_text: 'Create service',
      delete_service: 'Delete service',
      duration_tooltip_text_1:
        'The appointment duration combines the configured "Client Interaction Time" with additional time for each requested service',
      duration_tooltip_text_2:
        'Specify the additional time this service will add to an appointment',
      availability_tooltip_text:
        'Set whether this service is active and available for selection (by client and/or clinics)',
      name_tooltip_text: 'This name will be presented to your clients as well',
      visibility_tooltip_text:
        'Set whether this service can be booked by clients through online scheduling',
      delete_modal_title: 'Are you sure you want to delete service?',
      delete_modal_sub_title:
        'Deleting this service means that your clients and you will not be able to book this service anymore',
      assign_to_caregivers: 'Assigned caregivers'
    },
    edit_appointment_address: {
      title: 'Edit appointment address'
    },
    sign_in_modal: {
      title: 'Account with this phone number already registered',
      user_with: 'A user with phone number',
      already_exists: 'is already registered',
      CTA_lable: 'How would you like to proceed?',
      btn_text: 'Log in and Retry',
      footer_text: 'Use another phone number'
    },
    edit_client_details: {
      client_comment: {
        title: "Edit client's comment",
        subTitle: 'Client comment',
        placeHolder: 'Comments/chief-complaint of your client'
      },
      vet_comment: {
        title: 'Fill your private notes',
        subTitle: 'Private note',
        placeHolder: 'Any notes about the appointment (it is hidden from your client)'
      }
    },
    'on.the.map': 'On the map',
    'no.clients.found': 'No clients found',
    'deleted.successfully': 'Deleted Successfully',
    'created.successfully': 'Created Successfully',
    'updated.successfully': 'Updated Successfully',
    shop: {
      label: 'Shop',
      title: 'Oops! \uD83D\uDE41',
      desc1: 'Our store is currently unavailable in your area.',
      desc2: 'Don’t worry!',
      desc3: 'We’re on a mission to make it happen everywhere.',
      desc4: 'Stay tuned  for updates. Thanks for your patience! \uD83C\uDF1F',
      btnNotify: 'Notify me when available',
      btnBack: 'Back to Home page',
      success: 'You will be notified when service is available in your area',
      description:
        "Currently, you can't purchase food, meds, toys, and more from our store in your area. But don't fret! We're on a mission to make it happen everywhere. Stay tuned and check back soon for updates. \uD83C\uDF1F Thanks for your patience!"
    },
    'date-time-format': {
      'full-date': 'MM/DD/YYYY'
    },
    forms: {
      pet: {
        name: 'Name is required field',
        type: 'Species is required field',
        gender: 'Sex is required field',
        age: 'Invalid number',
        isSterilized: 'Reproductive status is required field'
      },
      appointment: {
        concern: 'You should select at least one concern',
        comment: 'Comment is required field'
      }
    },
    'vet-appointments': {
      title: 'Appointments',
      houseCountLabel: 'Households',
      petsCountLabel: 'pets',
      newAppLabel: 'New Appointment',
      reorderLabel: 'Reorder',
      listViewLabel: 'List view',
      mapViewLabel: 'Map view',
      noCommittedETALabel: 'No committed ETA',
      originalScheduleLabel: 'Originally scheduled to:',
      visitDurationLabel: 'Visit duration',
      firstVisitLabel: 'First visit',
      servicesLabel: 'Services',
      userCommentLabel: 'Chief-complaint',
      privateCommentLabel: 'Private notes',
      commentPlaceHolder: 'Enter notes',
      addBtnLabel: '+ Add',
      saveBtnLabel: 'Save',
      placeholderLabel: 'type your comment',
      committedETAHelp:
        'This time window represents the estimated arrival time window sent to the client',
      noCommittedETAHelp:
        "Appointments created after ETAs are sent won't have automatic ETAs. You can either set it manually, or unlock the route, make edits, and resend ETAs",
      ETARouteRegister:
        'ETA will be calculated when the route is locked for new appointments (a day before the route at 2:00 pm).',
      ETALabelRegister: 'ETA to be determined',
      moreInfo: 'more info'
    },
    'route-summary': {
      title: 'Route Summary',
      totalDistance: 'Total distance:',
      km: 'km',
      totalTime: 'Total time',
      min: 'min',
      routing: 'Routing',
      appointments: 'Appointments:',
      overTime: 'Over time',
      free: 'Free time',
      numberOfHouseholds: 'Number of households',
      numberOfPatients: 'Number of patients',
      estimatedTime: 'Est.',
      toBeDetermined: 'TBD',
      totalDistanceInfoText:
        'The route length will be calculated once the route is automatically locked for new appointments (a day before at 2:00 pm, or manually before that)',
      routingInfoText:
        'Exact routing times will be calculated once the route is automatically locked for new appointments (a day before at 2:00 pm, or manually before that)',
      ETALabelRegister: 'ETA to be determined',
      miles: 'miles'
    },
    schedule: {
      filters_title: 'Filters',
      clear_filters_button: 'Clear filters',
      open_recommender_button: 'Create new appointment',
      schedule_modal: {
        title_timeslot: 'Timeslot details',
        title_appointment: 'Appointment details',
        timeslot_details: {
          timeslot_name_lable: 'Timeslot',
          location_lable: 'Location',
          date_lable: 'Date',
          hour_lable: 'Hours',
          caregiver_lable: 'Caregiver',
          full_lable: 'Full',
          locked_lable: 'Locked',
          title_appointments_list: 'Appointments',
          appointment_list: {
            no_appointments: 'No appointments yet!',
            lbl_address: 'Address',
            lbl_date: 'Date',
            lbl_hours: 'Appointment hours',
            lbl_eta: 'ETA window sent to client',
            lbl_pets: 'Pets',
            lbl_reason: 'Service',
            lbl_chief_comlaint: 'Chief comlaint',
            lbl_vet_comments: 'Caregiver comments',
            files: 'Files',
            lbl_age: 'Age',
            lbl_sex: 'Sex'
          }
        }
      }
    },
    admin: {
      'updated.successfully': 'Updated Successfully',
      general: {
        back: 'Cancel',
        floor: 'Floor',
        title: 'Kumba Web',
        description: 'Add and view different trips',
        submit: 'Submit',
        try_later: 'Unexpected error, please try again later',
        name: 'Name',
        search: 'Search',
        search_name: 'Search with name',
        reload: 'Reload',
        reset: 'Reset',
        actions: 'Actions',
        add_new: 'Add New',
        title_list: 'List',
        operation_success: 'Operation Success',
        yes: 'Yes',
        no: 'No',
        confirm: 'Are you sure?',
        page_not_found: 'Page not found',
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
        unauthorized: 'Unauthorized',
        cancel: 'Cancel',
        confirm_delete: 'Are you sure to delete this caregiver?',
        confirm_delete_timeslot: 'Are you sure to delete this time slot?',
        male: 'Male',
        female: 'Female',
        birth_date: 'Date of birth',
        fill_missing_field: 'Please, fill in missing field!',
        check_address: 'Check address fields',
        confirm_changes: 'Are you sure you want to save these change(s)?',
        confirm_status_change: 'Are you sure you want to make this profile Active/Inactive?'
      },
      menu: {
        schedule: 'Schedule',
        import_users: 'Import Users',
        user_managment: {
          vets: 'Caregivers',
          customers: 'Customers'
        },
        logout: 'Log out'
      },
      schedule: {
        filters_title: 'Filters',
        clear_filters_button: 'Clear filters',
        open_recommender_button: 'Create new appointment',
        schedule_modal: {
          title_timeslot: 'Timeslot details',
          title_appointment: 'Appointment details',
          timeslot_details: {
            timeslot_name_lable: 'Timeslot',
            location_lable: 'Location',
            date_lable: 'Date',
            hour_lable: 'Hours',
            caregiver_lable: 'Caregiver',
            full_lable: 'Full',
            locked_lable: 'Locked',
            title_appointments_list: 'Appointments',
            appointment_list: {
              no_appointments: 'No appointments yet!',
              lbl_phone: 'Phone number',
              lbl_email: 'Email address',
              lbl_address: 'Address',
              lbl_date: 'Date',
              lbl_hours: 'Appointment hour',
              lbl_eta: 'ETA sent to client',
              lbl_pets: 'Pets',
              lbl_reason: 'Service',
              lbl_chief_comlaint: 'Chief comlaint',
              lbl_vet_comments: 'Caregiver comments',
              files: 'Files',
              lbl_age: 'Age',
              lbl_sex: 'Sex'
            }
          }
        }
      },
      forms: {
        email: 'Email',
        password: 'Password',
        enter_correct_email: 'Please, enter correct email address',
        enter_correct_number: 'Please, enter correct number',
        enter_name: 'Please, enter name'
      },
      register: {
        title: 'Register',
        fullname: 'Full Name',
        confirm_password_required: 'Please confirm your password!',
        passwords_do_not_match: 'The two passwords that you entered do not match!',
        fullname_required: 'Please input your full name!',
        confirm_password: 'Confirm password',
        min_password: 'Minimum password length is 6 characters',
        min_fullname: 'Minimum full name length is 4 characters',
        register_success: 'Successfully registered, you can now log in',
        password_too_weak:
          'Password must contain at least 1 number, 1 uppercase and 1 lowercase letters.'
      },
      login: {
        title: 'Login',
        email_required: 'Email is required',
        email_invalid: 'Please enter valid email address!',
        password_required: 'Please input your password!',
        invalid_data_title: 'Incorrect data',
        invalid_data_description: 'Please check your data and try again',
        error_user_data: 'Error while getting user data',
        session_expired_title: 'Session expired',
        session_expired_description: 'Please re-login with your credentials'
      },
      validation: {
        'The email has already been taken': 'The email has already been taken'
      },
      trips: {
        title: 'Kumba',
        view_monthly_trip: 'Trips for next month',
        title_page_list: 'Trips List',
        title_page_view: 'View Trip',
        title_page_add: 'Add Trip',
        title_page_edit: 'Edit Trip',
        title_all: 'All Trips',
        destination: 'Destination',
        days_left: 'Days to start',
        end_at: 'End date',
        start_at: 'Start Date',
        destionation_required: 'Destination required',
        start_end_date: 'Start & End Date',
        start_end_date_required: 'Start & End Date Required',
        comment: 'Comment',
        comment_required: 'Comment required',
        search_title: 'Search',
        destination_required: 'Destination is required',
        dashboard: 'Dashboard'
      },
      users: {
        title: 'Users',
        title_page_list: 'Users List',
        title_page_view: 'View User',
        title_page_add: 'Add User',
        title_page_edit: 'Edit User',
        title_plural: 'Users',
        role_admin: 'Admin',
        role_manager: 'Manager',
        role_user: 'User',
        log_out: 'Log out',
        profile: 'Profile',
        name: 'Full Name',
        name_required: 'Full Name is required',
        role: 'Role',
        role_required: 'Role is required',
        user_id: 'User/User ID',
        registered_at: 'Registration Date',
        search_title: 'Search name/email',
        reports: 'Reports',
        settings: 'settings',
        management: 'Users Management',
        customers: 'Customers',
        visits: 'Visits',
        vets: 'Caregivers',
        id: 'User ID',
        registration_date: 'Registration Date',
        phone: 'Phone',
        country: 'Country',
        working_area: 'Working Area',
        working_days: 'Working Days & Hours',
        status: 'status',
        occupied: 'Occupied :',
        floor: 'Floor',
        apartment: 'Apartment',
        address: 'Address',
        delete_subscription_confirm: 'Delete this subscription?',
        delete_card_confirm: 'Delete this card?',
        update_success: 'User data updated successfully',
        active_subscriptions: 'Active Subscriptions',
        cards: 'Cards'
      },
      totals: {
        number_of_customers: 'Total Customers: ',
        canceled_subscriptions: 'Canceled Subscriptions: ',
        inactive: 'Inactive: ',
        total_spending: 'Total Spending: $',
        active_subscriptions: 'Active Subscriptions: ',
        one_time_subscriptions: 'One-Time Subscriptions: ',
        number_pets: 'Total Pets: ',
        export_list: 'Export List'
      },
      vet: {
        add_edit_new_vet: 'Edit / Add New Caregiver ',
        home_address: 'Home Address',
        start_point: 'Start Point',
        end_point: 'End Point',
        upload: 'Upload',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        social_number: 'Social Number',
        status: 'Status:',
        active: 'Active',
        inactive: 'Inactive',
        occupied: 'Occupied',
        license_number: 'License Number',
        main_commute_method: 'Main Commute Method',
        car: 'Car',
        bike: 'Bike',
        bicycle: 'Bicycle',
        walk: 'Walk',
        experience: 'Experience',
        mobile_number: 'Mobile Number',
        select_timezone: 'Select a Timezone',
        gender: 'Sex',
        building_number: 'Building number',
        zipcode: 'Zip code (for US only)',
        street: 'Street',
        city: 'City',
        country: 'Country',
        limit_reached: 'Limit reached'
      },
      financials: {
        bank_code: 'Bank Code',
        bank_branch: 'Bank Branch',
        swift: 'SWIFT BIC',
        bank_name: 'Bank Name',
        bank_account: 'Bank Account Number',
        short_biography: 'Short Biography',
        create_vendor: 'Create a vendor',
        payment_provider: 'Payment Provider',
        seller_code: 'Sellet Inc Code',
        social_issue_date: 'Social ID issue Date',
        bank_account_class: 'Bank Account Class',
        bank_account_type: 'Bank Account Type',
        account_id: 'Account ID',
        bank_state: 'Bank State',
        bank_city: 'Bank City'
      },
      recommender: {
        address_details: {
          hide: 'Hide',
          add: 'Add',
          additional_address_info: 'additional address info',
          property_type: 'Location type',
          private_house: 'House',
          apartment: 'Apartment',
          address_comments: 'Special instructions',
          flat: 'Appartment/unit'
        },
        new_appointment: {
          stepper_first_title: 'Select Client',
          stepper_second_title: 'Select Time Slot',
          stepper_third_title: 'Patient Details',
          new_client: 'New Client',
          search_placeholder: 'Search for a client',
          empty_state_title: 'No clients',
          empty_state_description: 'Share your scheduling link so owners can sign up',
          address: 'Address:',
          success_message: 'Appointment has been created successfully',
          reschedule_appointment: 'Reschedule Appointment',
          cancel_appointment: 'Cancel Appointment',
          view_duration: 'View/edit Duration',
          set_duration_title: 'Set appointment duration',
          set_duration_text:
            'Changing the appointment duration\nis affecting the <strong>ETA</strong> of the next appointments',
          input_suffix: 'Min',
          cancel_appointment_title: 'Cancel Appointment',
          cancel_appointment_text: 'Are you sure you want to continue?',
          reschedule_appointment_title: 'Reschedule Appointment',
          calendar_title: 'Choose date',
          time_slots_for_date: 'Available timeslots:',
          filters: 'Filters',
          show_full_ts: 'Show full/locked timeslots',
          show_ts_in_areas: 'Show timeslots in other areas',
          duration_success: 'Duration has been updated successfully',
          cancel_success: 'Appointment has been cancelled successfully',
          reschedule_success: 'Appointment has been rescheduled successfully',
          yes_cancel: 'Yes, Cancel appointment'
        },
        scheduling_stepper: {
          vet_first_step_title: 'Select Time Slot',
          vet_second_step_title: 'Patient Details',
          vet_third_step_title: 'Client Details',
          vet_address_title: 'Client address',
          vet_special_instructions_text: 'e.g. Parking options, special instructions',
          vet_personal_step_title: 'Client info and contact'
        },
        patient_details: {
          add_info: 'Add information about your pets',
          add_info_vet: 'Appointment details',
          select_patients: 'Select patients',
          pet_type: 'Species',
          pet_name: 'Name',
          add_a_pet: 'Add a Pet',
          add_pet: 'Add Pet',
          pets_condition:
            "Additional details about your pet's condition that will help your caregiver be prepared",
          condition_label: "Tell us more about your pet's condition",
          vet_pets_condition: 'Comments about patients medical status, complaints etc.',
          vet_condition_label: 'Comments',
          vet_concern_label: 'Appointment reason',
          concern_label: "What's the concern",
          'please.fill.fields': 'Please fill all the required fields first',
          select: 'Select',
          choose_pets: 'Select pets*',
          first_time_visit_title: 'First time visit for one or more pets',
          first_time_visit_tooltip:
            'This is a first time visit for some or all of the selected pets'
        },
        personal_details: {
          'add.information.about.yourself': 'Add information about yourself',
          firstName: 'First name',
          lastName: 'Last name',
          email: 'Email',
          phone_number: 'Phone number'
        },
        edit_address: {
          title: "Edit Client's Address",
          lbl_address: 'Client address'
        }
      },
      appointments: {
        forms: {
          pet: {
            name: 'Name is required field',
            type: 'Species is required field',
            gender: 'Sex is required field',
            age: 'Invalid number',
            isSterilized: 'Reproductive status is required field'
          },
          appointment: {
            concern: 'You should select at least one concern',
            comment: 'Comment is required field'
          }
        }
      }
    },
    vet_timeslots: {
      reassign_vet: 'Reassign caregiver',
      generate_modal_title: 'You’re going to generate the route',
      generate_description:
        'This action will generate the route for the whole day and will lock the route for additional new appointments',
      confirm_generate: 'Generate route',
      lock_modal_title: 'Are you sure you want to lock the route?',
      confirm_lock: 'Lock & send ETAs',
      confirm_lock_secondary: 'Lock without sending ETAs',
      lock_description: 'ETA messages will be automatically sent to your customers',
      unlock_modal_title: 'Are you sure you want to edit the route?',
      confirm_unlock: 'Yes, Edit route',
      unlock_description:
        'ETA messages were already sent to your customers, any changes might affect the ETAs',
      mark_full: 'Full',
      mark_locked: 'Locked',
      generate_route: 'Generate route',
      lock_route: 'Mark as ready',
      lock_route_lock: 'Lock route',
      unlock_route_lock: 'Unlock route',
      unlock_route: 'Mark as unready',
      copy_ts: 'Duplicate slot',
      edit_ts: 'Edit time slot',
      delete_ts: 'Delete time slot',
      message: {
        unlock_success: 'Route has unlocked',
        generate_success: 'Route has generated',
        lock_success: 'Route has locked'
      }
    },
    generate_route_modal: {
      title: 'This route was created after hours, so an optimal route was not generated.',
      title_with_etas:
        'This route was created after hours, so an optimal route and ETAs were not sent.',
      description:
        'Would you like to proceed with generating the route and sending ETAs to clients',
      button: 'Generate Route',
      button_with_etas: 'Generate Route and Send ETAs',
      button_cancel: 'I’ll do it later'
    },
    integration: {
      title_success: 'Success!',
      'message.integration_created':
        'You have been authenticated successfully. You will receive an email with the next steps within 2 business days.',
      'message.integration_updated': 'Credentials have been updated!',
      'button.save': 'Save',
      'button.no': 'No',
      'button.ok': 'OK',
      'button.cancel': 'Cancel',
      'button.connect': 'Connect',
      'button.ignore': 'Ignore',
      'button.map_caregivers': 'Map Caregivers',
      'button.disable': 'Disable',
      'button.yes_disable_integration': 'Yes, Disable Integration',
      'button.disable_integration': 'Disable Integration',
      'button.back': 'Back',
      'button.dont_complete_integration': 'Skip Integration',
      'section.active.title': 'Connected Integrations',
      'section.active.empty_list': 'There are no active integrations yet',
      'section.integrations.title': 'Integrations list',
      'section.integrations.empty_list':
        'There are no relevant integrations for you yet. For more details, please ',
      'label.last_update': 'Last Update',
      'label.chrome_ext_required': 'Chrome Extension Required',
      'label.disable_integration': 'Disable Integration with:',
      'label.download_extension': 'Download Extension',
      'label.complete_integration': 'Complete Integration',
      'label.caregivers_mapping': 'Caregiver Mapping',
      'label.attention': 'Attention',
      'label.caregivers_mapping_info': 'Modify by dragging & dropping into the right column',
      'label.connect_with_integration': 'Connect with {{integration}}',
      'description.complete_integration':
        "To complete integration, download our Chrome extension. It's crucial for synchronization and smooth operation.",
      'description.caregivers_mapping':
        'Verify links between <span>Kumba</span> and <b>{{integration}}</b> caregivers and adjust if needed',
      'description.dragging': 'Drag cards to appropriate positions above:',
      'description.dragPosition': 'Drag user here',
      'description.all_caregivers_linked': 'All caregivers are linked',
      'description.config_description':
        'To enable integration, fill required fields, connect, and grant necessary permissions',
      'description.question_want_to_continue': 'Are you sure you want to proceed?',
      'description.caregivers_have_not_mapped':
        'Some caregivers are unmapped, potentially affecting the integration process',
      'description.chrome_ext_required':
        'For seamless integration and accurate data transfer, install the Chrome extension. Without it, synchronization may be compromised.',
      credentials_editor: {
        title: 'Connect with ',
        description:
          'To enable integration, fill required fields, connect, and grant necessary permissions',
        button_save: 'Update Credentials'
      },
      error_popover: {
        title: 'PIMS sync issue',
        'button.fix_it': 'Fix it',
        timeslot_area_message: 'Some appointments were not synced',
        appointment_error_message: 'Failed to sync appointment with PIMS. Please contact support',
        owner_error_message: 'Failed to sync pet owner with PIMS. Please contact support',
        pet_error_message: 'Failed to sync pet with PIMS. Please contact support'
      },
      edit_pet_form: {
        edit_external_id_warning_modal: {
          confirm_button: 'Edit Anyway',
          title: 'Editing ID may break client sync',
          content:
            `Changing this patient's ID could break the synchronization with your PIMS,` +
            `potentially affecting this pet, the client the other client's patients and their appointments. Proceed with caution.`
        },
        edit_external_id_modal: {
          title: 'Steps for editing the synced patient',
          first_step: 'Enter to the desired patient profile in the practice management software',
          second_step: 'Copy the patient reference id',
          third_step: 'Replace the current reference ID below by pasting the new one:',
          note: '<span>Note:</span> Choosing a patient of a different client will un-sync others patients as well, requiring you to manually resync them.',
          success_message: 'External id has changed'
        }
      }
    },
    missing_info: {
      continue_anyway: 'Continue anyway',
      go_back: 'Go back',
      go_back_question: 'Would you like to go back and add the missing information?',
      email_title: 'This client is missing an email address',
      phone_title: 'This client is missing a phone number',
      address_title: 'This client is missing an address',
      email_description:
        'Without an email address, the client might not receive notifications and reminders.',
      phone_description:
        'Without a phone number, the client will not receive notifications and reminders, and they will <bold> not be able to sign in </bold> to their account.',
      address_description: 'Without an address, the client will not be able to book appointments.'
    },
    settings_page: {
      service_area: {
        validation_modal: {
          title: 'Unable to Save Service Area'
        },
        create_area_modal: {
          validations: {
            name_required: 'Service area name is required',
            name: 'A service area name is required.',
            zipCodes: 'At least one ZIP code must be selected',
            polygons: 'At least one Polygon must be drawn'
          },
          title_create: 'Create New Service Area',
          title_edit: 'Edit Service Area',
          label_areaName: 'Service Area Name',
          placeholder_areaName: 'Name your service area',
          label_type: 'Create area by',
          title_type_zip: 'ZIP Codes',
          title_type_zip_note: '(US only)',
          title_type_polygon: 'Geofence',
          desc_polygon: 'Draw a custom boundary on the map',
          desc_zip: 'Enter ZIP codes for this service area',
          placeholder_map_search: 'Search...',
          button_newArea: 'Add new area',
          button_save: 'Save',
          exit_modal: {
            title: 'Are you sure you want to leave without saving?',
            content: 'Your changes will be discarded, and window will be closed',
            button_confirm: 'Discard & Exit'
          },
          button_show_areas: 'Show other areas',
          button_hide_areas: 'Hide other areas',
          save_modal: {
            title: 'Apply changes to all time slots?',
            desc: 'Choose whether to apply changes to all existing time slots that use this working area, or only to newly created ones.',
            option_all_1: 'Apply to ',
            option_all_2: 'all',
            option_all_3: ' linked time slots',
            option_new_1: 'Apply to ',
            option_new_2: 'new',
            option_new_3: ' ones only',
            button_confirm: 'Apply',
            button_cancel: 'Cancel',
            input_label: 'Name the new area *',
            input_info:
              'The previous working area will remain\nsaved, allowing you to continue\nmanaging the time slots linked to it',
            input_error_req: '* Name is required'
          },
          save_modal_timeslot: {
            title: 'Save this service area for future use?',
            content:
              'If you think this working area will be useful again, save it for future time slots. Otherwise, you can continue without saving if it’s not needed in the future.',
            button_confirm: 'Save for Future Use',
            button_cancel: 'Continue Without Saving'
          }
        },
        editor_zip: {
          title: 'Select zip codes',
          note: '',
          select_placeholder: 'Type a zip code to see options'
        },
        tutorial: {
          page_title: 'Service Areas',
          add_button: 'Create Service Areas',
          description:
            "Easily create and manage the service areas for your clinic's fleet. Draw polygons or select zip codes to define service areas, and save them for quick use when scheduling time slots"
        },
        areas: {
          title: 'Service Areas:',
          search_placeholder: 'Search',
          create_button: 'Create Service Area',
          total_areas: '{{total}} Service areas found',
          sort_by: 'Sort by',
          order_created_at: 'Newest First',
          order_updated_at: 'Last Updated',
          order_name: 'Name (A-Z)',
          areas_view: 'View all service areas on map',
          view_grid: 'List View',
          view_map: 'Map View',
          'button.more': 'Show +{{count}} more',
          'button.less': 'Show less',
          map_popup_title: 'Selected areas',
          map_popup_polygons_title: 'Areas:'
        },
        remove_modal: {
          title: 'Are You Sure You Want to delete This Service Area?',
          content:
            'You’re about to permanently delete this service area. It cannot be recovered. However, it won’t affect the time slots where it’s already in use.',
          button_confirm: 'Delete'
        },
        summary_areas_modal: {
          title: 'Your Saved Service Areas'
        },
        tile: {
          areas: 'Service Areas:'
        }
      }
    },
    create_timeslot: {
      validations: {
        title: 'Unable to save time slot due to the following reasons',
        working_areas: 'Select at least one service area'
      }
    },
    owner_contact_info_popover: {
      phone: 'Phone:',
      email: 'Email:',
      city: 'City:',
      street: 'Street:',
      add_info: 'Add missed info'
    },
    admin_dashboard: {
      total_appointments: 'Total Appointments',
      list_appointments: 'List of appointments',
      appt_date: 'Appt date',
      client: 'Client',
      caregiver: 'Caregiver',
      total_appointments_qty: 'Total appointments QTY',
      monthly_appointments: 'Appointment Summary by Month',
      filter_by: 'Filter by'
    },
    vet_admin: {
      clients_table: {
        registration_date: 'Registration Date',
        full_name: 'Full Name',
        contacts: 'Contacts',
        pets_number: 'Number of Pets',
        next_appointment_date: 'Next Appt Date',
        action: 'Action'
      }
    },
    recurring_time_slot: {
      non_editable: 'Non-editable',
      non_editable_tooltip:
        'To modify the recurrence pattern, duplicate this slot, edit the new one, and delete the old. Deleting won’t impact existing appointments.',
      create: 'Create time slot',
      edit: 'Edit recurring time slot',
      name: 'Recurring time slot name',
      name_ts: 'Time slot name',
      name_the_time_slot: 'Name the time slot',
      repeat_every: 'Repeat every',
      starts_from: 'Starts from',
      ends: 'Ends',
      create_working_area: 'Create working area',
      zip_code_us_only: '(Zip code supports US only)',
      add_from_saved_polygons: 'Add from saved polygons',
      choose_areas: 'Choose areas',
      create_new_area: 'Create new area',
      save: 'Save',
      delete_time_slot: 'Delete time slot',
      vet_list_title: 'Selected caregiver',
      timeslot_list_title: 'Recurring time slots',
      search_caregiver_placeholder: 'Search for caregiver',
      confirm_delete: 'Are you sure you want to delete this time slot?',
      delete_info:
        'Deleting a recurring time slot will NOT affect dates with already scheduled appointments, and you will have to delete such dates manually.',
      confirm_delete_input: 'To remove time slot type “DELETE”',
      repeat_rule: 'Repeat rule',
      start_date: 'Start date',
      end_date: 'End Date',
      never: 'Never',
      after: 'After',
      occurrences: 'occurrences',
      include: 'Include slots with scheduled appointments.',
      update: 'Update time slot',
      exclude: 'Exclude time Slot',
      exclude_confirm: 'Are you sure you want to exclude this time slot?',
      exclude_success: 'Time slot excluded successfully',
      exclude_error: "Time slot cannot be exluded. Please reschedule all it's appointments first",
      errors: {
        name: 'Name is required field',
        addressPoint: 'Incorrect address',
        maxAppointments: 'Should be a positive number',
        workingAreas: 'Select at least one working area',
        startTime: 'Start time is required field',
        endTime: 'End time is required field'
      }
    }
  },
  breadcrumb: {
    home: 'CAREGIVER',
    vet_routes: 'SCHEDULE',
    vet_clients: 'CLIENTS',
    vet_profile: 'PROFILE',
    clinic: 'CLINIC',
    settings: 'SETTINGS',
    clinic_schedule: 'SCHEDULE',
    clinic_clients: 'CLIENTS',
    clinic_vets: 'CAREGIVERS',
    clinic_apptmanager: 'APPT MANAGER',
    clinic_areas: 'SERVICE AREA',
    clinic_integrations: 'INTEGRATIONS',
    clinic_plans: 'PLANS & USAGE',
    clinic_services: 'SUPPORTED SERVICES',
    clinic_slots: 'TIME SLOTS',
    vet_slots: 'TIME SLOTS',
    schedule_book: 'BOOK'
  },
  stats: {
    empty: 'No appointments this month',
    column_date: 'Appointment date',
    column_clients: 'Clients',
    column_caregiver: 'Caregiver'
  },
  appt_details: {
    concern_label: "What's the concern?",
    concern_placeholder: 'Choose concern',
    private_comment: 'Private notes',
    private_placeholder: 'These notes are not visible to clients',
    user_comment_clinic: 'Chief complaint',
    user_comment: 'Comments',
    user_placeholder:
      "Additional details about your pet's condition that will help your caregiver come prepared",
    first_time_label: 'First time appointment with this caregiver',
    upload_label: 'Upload medical history',
    cancel: 'Cancel',
    save: 'Save'
  },
  timeslot_list: {
    reassignVetModal: {
      title: 'Apply time slot to different caregiver',
      button: 'Apply',
      success_status: 'Vet has been reassigned successfully'
    }
  },
  timeslot_modal: {
    assigned_vets_label: 'Assigned caregiver',
    assigned_vets_placeholder: 'Enter caregiver name'
  },
  vet_multiselect: {
    all_providers_option: 'All providers',
    label_all: 'All caregivers',
    label_few: '{{count}} caregivers',
    placeholder: 'Select caregivers'
  },
  reschedule: {
    filters: 'Filters',
    show_full_ts: 'Show full timeslots',
    show_locked_ts: 'Show locked timeslots',
    show_all_areas: 'Show timeslots not in the area',
    show_other_vets: 'Show other caregivers',
    select_all_vets: 'Select all',
    sort_by: 'Sort by:',
    no_timeslots: 'There are no timeslots available for the selected date and filters',
    select_timeslot: '* Please select a time-slot',
    btn_reschedule: 'Reschedule'
  }
};
