import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { message, Popconfirm, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dropdown, getVetName, cssText, Modal, ButtonColors } from '_fsd/shared';
import { ReactComponent as IconRoute } from 'assets/icons/route.svg';
import { ReactComponent as IconSwap } from 'assets/icons/swap.svg';

import {
  unlockRouteByIdMutation,
  excludeTimeSlotMutation,
  lockRouteByIdMutation,
  toggleIsLockedMutation,
  updateRouteStatusMutation,
  excludeDateFromRecurringTimeSlotMutation
} from 'gql';

import { parseTimeSlotHour } from 'utils/helpers';
import TimeSlotWorkingAreas from 'components/timeslot-working-areas/TimeSlotWorkingAreas';
import css from '../../../pages/vet-clients/ui/outdate/Customers.module.css';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  CopyOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  LockOutlined,
  UnlockOutlined
} from '@ant-design/icons';

import cls from 'classnames';
import ModalC from '../../../../components/timeslot-warning-modal/TimeslotWarningModal';
import { routeStatus } from '../../../../components/timeline/appointments/useTimelineAppointments';
import { IntegrationErrorPopover, isAppointmentIntegrationError } from '_fsd/entities';
import tsCss from '../ui/Timeslots.module.css';
import { ReassignVetModal } from '../ui/reassignVetModal/ReassignVetModal';
import AuthContext from '../../../../contexts/AuthContext';
import { Roles } from '../../../../constants/enums';

import { processDate } from 'pages/vet/new-appointment-modal/serializers';

export const ActionColumn = ({
  record,
  toggleIsLockedHandler,
  setUpdateTimeslotData,
  excludeTimeSlotHandler,
  setSelectedTimeslot,
  refetchTimeslots,
  calendarDate
}) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [updateStatus] = useMutation(updateRouteStatusMutation, {
    refetchQueries: ['getTimeslots']
  });
  const [lockRoute] = useMutation(lockRouteByIdMutation);
  const [unlockRoute] = useMutation(unlockRouteByIdMutation);
  const [popupOpen, setPopupOpen] = useState(false);

  const route = record.routes?.[0];
  const isLocked = route?.isLocked;
  const isFull = route?.isFull;
  const warning = route?.status?.toLowerCase() === routeStatus.ADJUSTMENT && !isLocked;
  const isRegister = route?.status?.toLowerCase() === routeStatus.REGISTER;
  const isAdjustment = route?.status?.toLowerCase() === routeStatus.ADJUSTMENT;
  const isReady = route?.status?.toLowerCase() === routeStatus.READY;

  const tooltip = warning && t('route.lock_warning');

  // todo: move modals outside columns
  const [modal, setModal] = useState(false);
  const [modalGenerate, setModalGenerate] = useState(false);
  const [modalLock, setModalLock] = useState(false);
  const [modalUnlock, setModalUnlock] = useState(false);
  const [modalReassign, setModalReassignVet] = useState(false);

  const items = [
    {
      key: 13,
      label: (
        <div className={css.actionItem}>
          <div className={css.actionColumnDropdown}>
            {!isLocked ? (
              <LockOutlined
                id="debugdebugdebugdebugdebugdebugdebug"
                className="copy-icon"
                // style={{ color: 'red' }}
              />
            ) : (
              <UnlockOutlined className="copy-icon" />
            )}
            <span className={cssText.s14w5h20i}>
              {isLocked ? t('vet_timeslots.unlock_route_lock') : t('vet_timeslots.lock_route_lock')}
            </span>
          </div>
        </div>
      ),
      onClick: () => {
        if (isLocked) {
          if (isRegister || route?.status === undefined) {
            toggleIsLockedHandler(record.uid, record.date);
          } else {
            setModal(true);
          }
        } else {
          toggleIsLockedHandler(record.uid, record.date);
        }
      }
    },
    user.role === Roles.VET_ADMIN
      ? {
          key: 3,
          label: (
            <div className={css.actionItem}>
              <div className={css.actionColumnDropdown}>
                <IconSwap className="copy-icon" />
                <span className={cssText.s14w5h20i}>{t('vet_timeslots.reassign_vet')}</span>
              </div>
            </div>
          ),
          onClick: () => {
            setModalReassignVet(true);
          }
        }
      : undefined,
    isRegister
      ? {
          key: 0,
          label: (
            <div className={css.actionItem}>
              <div className={css.actionColumnDropdown}>
                <IconRoute className="copy-icon" />
                <span className={cssText.s14w5h20i}>{t('vet_timeslots.generate_route')}</span>
              </div>
            </div>
          ),
          onClick: () => {
            setModalGenerate(true);
          }
        }
      : undefined,
    isAdjustment
      ? {
          key: 1,
          label: (
            <div className={css.actionItem}>
              <div className={css.actionColumnDropdown}>
                <CheckCircleOutlined className="copy-icon" />
                <span className={cssText.s14w5h20i}>{t('vet_timeslots.lock_route')}</span>
              </div>
            </div>
          ),
          onClick: () => {
            setModalLock(true);
          }
        }
      : undefined,
    isReady
      ? {
          key: 2,
          label: (
            <div className={css.actionItem}>
              <div className={css.actionColumnDropdown}>
                <CloseCircleOutlined className="copy-icon" />
                <span className={cssText.s14w5h20i}>{t('vet_timeslots.unlock_route')}</span>
              </div>
            </div>
          ),
          onClick: () => {
            setModalUnlock(true);
          }
        }
      : undefined,
    {
      key: 10,
      label: (
        <div className={css.actionItem}>
          <div className={css.actionColumnDropdown}>
            <CopyOutlined className="copy-icon" />
            <span className={cssText.s14w5h20i}>{t('vet_timeslots.copy_ts')}</span>
          </div>
        </div>
      ),
      onClick: () => {
        setUpdateTimeslotData({
          ...record,
          uid: null
        });
      }
    },
    {
      key: 11,
      label: (
        <div className={css.actionItem}>
          <div className={css.actionColumnDropdown}>
            <EditOutlined className="write-icon" />
            <span className={cssText.s14w5h20i}>{t('vet_timeslots.edit_ts')}</span>
          </div>
        </div>
      ),
      onClick: () => {
        setSelectedTimeslot(record);
        setUpdateTimeslotData(record);
      }
    },
    {
      key: 12,
      label: (
        <div className={css.actionItem}>
          <Popconfirm
            placement="top"
            title={t('are.you.sure.remove.timeslot')}
            okText="Yes"
            cancelText="No"
            open={popupOpen}
            onConfirm={() => {
              excludeTimeSlotHandler(record.uid, record.isRecurring, record.date);
            }}
            id="delete-popconfirm">
            <div className={cls(css.actionColumnDropdown, css.iconRemove)}>
              <DeleteOutlined className="trash-icon" color="red" />
              <span className={cssText.s14w5h20i}>{t('vet_timeslots.delete_ts')}</span>
            </div>
          </Popconfirm>
        </div>
      ),
      onClick: () => {
        setPopupOpen(!popupOpen);
      }
    }
  ];

  return (
    <>
      <div
        className={css.actionColumn}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {isFull && (
          <Tag
            color="red"
            className={cls(css.tag, {
              [css.full]: isFull
            })}>
            {t('vet_timeslots.mark_full')}
          </Tag>
        )}
        {isLocked && (
          <Tag
            color="orange"
            className={cls(css.tag, {
              [css.full]: isLocked
            })}>
            {t('vet_timeslots.mark_locked')}
          </Tag>
        )}
        {warning ? (
          <Tooltip placement="top" title={tooltip}>
            <ExclamationCircleFilled style={{ color: '#FF9A05' }} />
          </Tooltip>
        ) : null}
        <Dropdown
          menu={{ items }}
          className={css.dropDown}
          trigger={['click']}
          getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}>
          <MoreOutlined />
        </Dropdown>
      </div>
      {modal && (
        <ModalC
          onClose={() => setModal(false)}
          onConfirm={() => {
            setModal(false);
            toggleIsLockedHandler(record.uid, record.date);
          }}
        />
      )}
      {modalGenerate && route && isRegister && (
        <Modal
          className={css.generateModal}
          icon={<IconRoute className={css.iconRoute} />}
          isOpen={modalGenerate}
          title={t('vet_timeslots.generate_modal_title')}
          onClose={() => setModalGenerate(false)}
          footerProps={{ titleConfirm: t('vet_timeslots.confirm_generate') }}
          onConfirm={() => {
            setModalGenerate(false);
            updateStatus({
              variables: {
                record: { status: 'ADJUSTMENT', date: route.date, timeSlotId: record.uid }
              }
            }).then(() => {
              message.success(t('vet_timeslots.message.generate_success'));
              refetchTimeslots();
            });
          }}>
          <span className={cls(cssText.s14h22w4, css.generateDescription)}>
            {t('vet_timeslots.generate_description')}
          </span>
        </Modal>
      )}
      {modalLock && route && isAdjustment && (
        <Modal
          className={css.lockModal}
          icon={<IconRoute className={css.iconRoute} />}
          isOpen={modalLock}
          title={t('vet_timeslots.lock_modal_title')}
          onClose={() => setModalLock(false)}
          footerProps={{
            titleConfirm: t('vet_timeslots.confirm_lock'),
            displaySecondaryConfirm: true,
            buttonSecondaryConfirmProps: {
              onClick: () => {
                setModalLock(false);
                lockRoute({
                  variables: { data: { routeId: route.uid, sendNotifications: false } }
                }).then(() => {
                  refetchTimeslots();
                  message.success(t('vet_timeslots.message.lock_success'));
                });
              },
              type: 'default',
              children: t('vet_timeslots.confirm_lock_secondary')
            }
          }}
          onConfirm={() => {
            setModalLock(false);
            lockRoute({
              variables: { data: { routeId: route.uid, sendNotifications: true } }
            }).then(() => {
              refetchTimeslots();
              message.success(t('vet_timeslots.message.lock_success'));
            });
          }}>
          <span className={cls(cssText.s14h22w4, css.generateDescription)}>
            {t('vet_timeslots.lock_description')}
          </span>
        </Modal>
      )}
      {modalUnlock && route && (
        <Modal
          className={css.generateModal}
          icon={<IconRoute className={css.iconRoute} />}
          isOpen={modalUnlock}
          title={t('vet_timeslots.unlock_modal_title')}
          onClose={() => setModalUnlock(false)}
          footerProps={{
            buttonConfirmProps: {
              type: 'default'
            },
            buttonCancelProps: {
              type: 'primary',
              colorScheme: ButtonColors.BLUE
            },
            titleConfirm: t('vet_timeslots.confirm_unlock')
          }}
          onConfirm={() => {
            setModalUnlock(false);
            unlockRoute({
              variables: { data: { routeId: route.uid } }
            }).then(() => {
              refetchTimeslots();
              message.success(t('vet_timeslots.message.unlock_success'));
            });
          }}>
          <span className={cls(cssText.s14h22w4, css.generateDescription)}>
            {t('vet_timeslots.unlock_description')}
          </span>
        </Modal>
      )}
      {modalReassign && (
        <ReassignVetModal
          onClose={() => setModalReassignVet(false)}
          open={true}
          timeslotId={record.uid}
          timeslot={record}
          excludeVetId={record.vet?.uid}
        />
      )}
    </>
  );
};

export const useTimeslots = (
  setUpdateTimeslotData,
  calendarDate,
  setSelectedTimeslot,
  refetchTimeslots,
  vetAdmin
) => {
  const { t } = useTranslation();
  const [excludeTimeSlot] = useMutation(excludeTimeSlotMutation);
  const [excludeDateFromRecurringTimeSlot] = useMutation(excludeDateFromRecurringTimeSlotMutation);
  const [toggleIsLocked] = useMutation(toggleIsLockedMutation);

  const toggleIsLockedHandler = async (timeSlotId, date) => {
    try {
      await toggleIsLocked({
        variables: {
          record: {
            date: processDate(date),
            timeSlotId
          }
        }
      });
      message.success(t('route.lock_message'));
      refetchTimeslots();
    } catch (err) {
      console.error(err);
    }
  };
  const excludeTimeSlotHandler = async (timeSlotId, isRecurring, date) => {
    try {
      const record = {
        timeSlotId,
        date: processDate(date)
      };
      const res = isRecurring
        ? await excludeDateFromRecurringTimeSlot({
            variables: {
              record
            }
          })
        : await excludeTimeSlot({
            variables: {
              record
            }
          });

      message.success(
        isRecurring ? t('recurring_time_slot.exclude_success') : t('vet.timeslots.exclude.success')
      );
      refetchTimeslots();
    } catch (err) {
      console.error(err);
      message.error(
        isRecurring ? t('recurring_time_slot.exclude_error') : t('vet.timeslots.exclude.error')
      );
    }
  };

  const timeslotsColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record) => {
        const isAnyIntegrationError = record.appointments?.some(
          ({ appointmentIntegrations, appointmentRouteId }) => {
            const route = record.routes.find((route) =>
              route.appointmentRoutes.find((ap) => ap.uid === appointmentRouteId)
            );

            return isAppointmentIntegrationError({ route, appointmentIntegrations, vetAdmin });
          }
        );

        return isAnyIntegrationError ? (
          <div className={tsCss.nameCell}>
            <IntegrationErrorPopover
              content={t('integration.error_popover.timeslot_area_message')}
              showTitle={false}>
              <ExclamationCircleFilled className={tsCss.warningIcon} />
            </IntegrationErrorPopover>
            <span>{record.name}</span>
          </div>
        ) : (
          record.name
        );
      }
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      align: 'center',
      render: (text, record) => <div>{getVetName(record?.vet)}</div>
    },
    {
      title: 'Working Area',
      dataIndex: 'workingArea',
      key: 'timeslotWorkingHours',
      align: 'center',
      render: (text, record) => <TimeSlotWorkingAreas timeSlot={record} />
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'timeslotHours',
      align: 'center',
      render: (text, record) => (
        <div className="hours-wrapper">{`${parseTimeSlotHour(
          record.startTime
        )} - ${parseTimeSlotHour(record.endTime)}`}</div>
      )
    },
    {
      dataIndex: 'actions',
      key: 'timeslotActions',
      align: 'center',
      render: (text, record) => {
        return (
          <ActionColumn
            record={record}
            refetchTimeslots={refetchTimeslots}
            toggleIsLockedHandler={toggleIsLockedHandler}
            setUpdateTimeslotData={setUpdateTimeslotData}
            excludeTimeSlotHandler={excludeTimeSlotHandler}
            setSelectedTimeslot={setSelectedTimeslot}
            calendarDate={calendarDate}
          />
        );
      }
    }
  ];

  return { timeslotsColumn, toggleIsLockedHandler, excludeTimeSlotHandler };
};
