import { INTEGRATION_ENTITY_STATUS, INTEGRATION_STATUS } from '_fsd/entities';
import { RouteStatus } from '_fsd/entities/route';

export const isAnyEntityIntegrationError = (vetAdmin, entityIntegrations) => {
  const isEntityHasIntegrationsError =
    entityIntegrations?.length <= 0 || entityIntegrations?.some(isEntityIntegrationError);

  const integrationIds = entityIntegrations?.map(({ integration }) => integration.uid) ?? [];
  const isEntityIntegrationNotExists = !!vetAdmin?.integrations.find(
    (i) => !integrationIds.includes(i.uid)
  );

  return (
    isVetAdminHasIntegrations(vetAdmin) &&
    (isEntityHasIntegrationsError || isEntityIntegrationNotExists)
  );
};

export const isEntityIntegrationError = (entityIntegration) =>
  entityIntegration.status === INTEGRATION_ENTITY_STATUS.ERROR &&
  entityIntegration.integration.status === INTEGRATION_STATUS.ENABLED;

export const isVetAdminHasIntegrations = (vetAdmin) =>
  vetAdmin?.integrations.filter((i) => i.status === INTEGRATION_STATUS.ENABLED).length > 0;

export const isAppointmentIntegrationError = ({ appointmentIntegrations, route, vetAdmin }) => {
  const isAppointmentIntegrationError =
    appointmentIntegrations <= 0 || appointmentIntegrations?.some(isEntityIntegrationError);

  const isNotDraftRouteStatus =
    !!route && route?.status !== RouteStatus.REGISTER && route?.status !== RouteStatus.ADJUSTMENT;

  return (
    isVetAdminHasIntegrations(vetAdmin) && isAppointmentIntegrationError && isNotDraftRouteStatus
  );
};
