import React from 'react';
import { Card, Descriptions, Collapse, message } from 'antd';
import { UserOutlined, CalendarOutlined, CopyOutlined } from '@ant-design/icons';
import './styles.css';
import { ReactComponent as PawIcon } from 'common_repo/assets/icons/black-paw.svg';
import File from './File.jsx';
import { parseTimeSlotHour, getAge, Modal, getName } from '_fsd/shared';
import { RescheduleWrapper } from '_fsd/widgets';
import { useTranslation } from 'react-i18next';
import { getPhone } from '../../../../entities';
const { Panel } = Collapse;

const SingleAppointmentDetails = ({ appointment, event, isTimeslotEventType, forceCloseModal }) => {
  const { t } = useTranslation();
  const [rescheduleModal, setRescheduleModal] = React.useState(false);
  const [view, onChangeView] = React.useState(true);

  const onCopy = (type) => {
    let value = '';
    if (type === 'phone') value = appointment?.user?.phonePrefix + appointment?.user?.phoneNumber;
    if (type === 'email') value = appointment?.user?.email;

    navigator.clipboard.writeText(value);
    message.success(t('copied.to.clipboard'));
  };

  return (
    <>
      <Collapse expandIconPosition={'end'} defaultActiveKey={['false']}>
        <Panel
          header={
            <div className="flex-row">
              <div className="flex-column">
                <div className="panel-header-client-name">
                  <UserOutlined className="icon" />
                  {`${getName(appointment?.subscription?.user)}`}
                </div>
                <div className="panel-header-pets">
                  <PawIcon className="icon" />
                  {appointment?.pets.map((pet) => pet.pet.name).join(' ,')}
                </div>
              </div>
              <div className="pl-20" onClick={(e) => e.stopPropagation()}>
                <div
                  className="sad-reschedule_icon"
                  title="Reschedule Appointment"
                  onClick={() => setRescheduleModal(true)}>
                  <CalendarOutlined title="Reschedule Appointment" />
                </div>
              </div>
            </div>
          }
          className="panel-appointment"
          key={isTimeslotEventType ? event : false}>
          <Descriptions
            className="panel-descriptions"
            column={1}
            labelStyle={{ fontWeight: 600, textDecoration: 'underline' }}
            contentStyle={{ textTransform: 'capitalize' }}>
            {getPhone(appointment?.user) && (
              <Descriptions.Item
                label={t(
                  'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_phone'
                )}>
                <a href={`tel:${getPhone(appointment?.user)}`}>{getPhone(appointment?.user)}</a>
                <button
                  name="phone"
                  onClick={(e) => onCopy(e.currentTarget.name)}
                  className="copy-icon-vs">
                  <CopyOutlined className="copy-svg" />
                </button>
              </Descriptions.Item>
            )}
            {appointment?.user?.email && (
              <Descriptions.Item
                label={t(
                  'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_email'
                )}>
                <a href={`mailto:${appointment?.user?.email}`}>{appointment?.user?.email}</a>
                <button
                  name="email"
                  onClick={(e) => onCopy(e.currentTarget.name)}
                  className="copy-icon-vs">
                  <CopyOutlined className="copy-svg" />
                </button>
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label={t(
                'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_address'
              )}>
              {event?.timeSlot?.routes?.[0]?.appointmentRoutes?.[0]?.address?.description}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_date')}>
              {new Date(event.startTime).toLocaleDateString()}
            </Descriptions.Item>
            {!isTimeslotEventType && (
              <Descriptions.Item
                label={t(
                  'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_hours'
                )}>
                {new Date(event?.startTime).toLocaleString('en-US', {
                  hour12: true,
                  timeStyle: 'short'
                }) +
                  ' - ' +
                  new Date(event?.endTime).toLocaleString('en-US', {
                    hour12: true,
                    timeStyle: 'short'
                  })}
              </Descriptions.Item>
            )}
            {!isTimeslotEventType && (
              <Descriptions.Item
                label={t(
                  'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_eta'
                )}>
                {event?.timeSlot?.routes?.[0]?.appointmentRoutes?.[0]?.etaStart && event?.timeSlot?.routes?.[0]?.appointmentRoutes?.[0]?.etaEnd
                  ? parseTimeSlotHour(event?.timeSlot?.routes?.[0]?.appointmentRoutes?.[0]?.etaStart).toLocaleString('en-US', {
                      hour12: true,
                      timeStyle: 'short'
                    }) +
                    ' - ' +
                    parseTimeSlotHour(event?.timeSlot?.routes?.[0]?.appointmentRoutes?.[0]?.etaEnd).toLocaleString('en-US', {
                      hour12: true,
                      timeStyle: 'short'
                    })
                  : 'TBD'}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label={t('admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_pets')}
              contentStyle={{ display: 'flex', flexWrap: 'wrap' }}>
              {appointment?.pets.map((pet) => {
                return (
                  <Card
                    key={pet.uid}
                    size="small"
                    title={pet.pet.name}
                    className="panel-decriptions-pet">
                    <Descriptions column={1} labelStyle={{ fontWeight: 500 }}>
                      <Descriptions.Item
                        label={t(
                          'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_age'
                        )}>
                        {pet.pet?.birthDate ? getAge(new Date(pet.pet?.birthDate)) : '--'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_sex'
                        )}>
                        {pet.pet?.gender ? pet.pet?.gender : '--'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_reason'
                        )}>
                        {pet.userConcerns
                          ?.map((c) => c.itemValue || c?.concern?.name)
                          .join(' | ') || 'None'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_chief_comlaint'
                        )}>
                        {pet?.comment || 'None'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          'admin.schedule.schedule_modal.timeslot_details.appointment_list.lbl_vet_comments'
                        )}>
                        {pet?.privateComment || 'None'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          'admin.schedule.schedule_modal.timeslot_details.appointment_list.files'
                        )}>
                        <div className="files">
                          {pet.files?.length
                            ? pet.files.map((f) => <File url={f} key={f} />)
                            : 'None'}
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                );
              })}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
      <Modal
        title="Reschedule Appointment"
        isOpen={rescheduleModal}
        onConfirm={() => setRescheduleModal(false)}
        onClose={() => setRescheduleModal(false)}
        force
        customFooter={() => null}>
        <RescheduleWrapper
          isVetAdmin
          defaultReschedule={false}
          close={() => {
            setRescheduleModal(false);
            forceCloseModal();
          }}
          onChangeView={onChangeView}
          vetId={event.vetId}
          currentAddress={appointment?.address}
          appointment={appointment}
          defaultTimeslot={event.timeSlot}
        />
      </Modal>
    </>
  );
};

export default SingleAppointmentDetails;
