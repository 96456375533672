import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { getPetOwnersQuery, signUpFlowMutation } from 'gql';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../contexts/AuthContext';
import useUpdateUser from '../edit-pet-owner/useUpdateUser';
import moment from 'moment';

const useNewAppointmentModal = (
  current,
  changeStep,
  vet,
  onRequestClose,
  setCurrent,
  getAppointmentsByTimeslotUid,
  openGenerateRouteModal = () => {},
  vetTimezoneSlug
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);

  const [selectedClient, setSelectedClient] = useState(null);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [petList, setPetList] = useState([]);
  const [petsToCreate, setPetsToCreate] = useState([]);
  const [nonAppPets, setNonAppPets] = useState([]);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  const [getPetOwners] = useLazyQuery(getPetOwnersQuery);

  const fetchPetOwners = (getNewData) => {
    try {
      return getPetOwners({
        variables: {
          record: {
            page: getNewData ? 1 : page,
            search,
            take: 10,
            countries: [],
            userId: vet?.user?.uid,
            skipAppointmentPets: true
          }
        },
        skip: !user?.role,
        fetchPolicy: 'no-cache'
      }).then((body) => {
        setPage(body.data.getPetOwners?.page + 1);
        if (search.length || getNewData) {
          setClients(body?.data?.getPetOwners?.petOwners);
        } else {
          setClients([...clients, ...body.data.getPetOwners.petOwners]);
        }
        setTotal(body.data.getPetOwners.total);
      });
    } catch (e) {
      console.log(e);
      setClients([]);
    }
  };

  const { updateUser } = useUpdateUser(fetchPetOwners, onRequestClose);

  const [signUp] = useMutation(signUpFlowMutation);

  // Reset selected client if clients list changes, and It's not available in the new list
  useEffect(() => {
    if (selectedClient?.uid && clients.length) {
      const client = clients.find((client) => client.user?.uid === selectedClient?.user?.uid);
      if (!client) setSelectedClient(null);
    }
  }, [selectedClient, clients]);

  useEffect(() => {
    fetchPetOwners().then(() => setLoading(false));
  }, []);

  useEffect(async () => {
    await fetchPetOwners(true).then(() => setLoading(false));
  }, [search]);

  const isNextButtonDisabled = useMemo(() => {
    const address = selectedClient?.user?.address;
    return (
      !clients?.length ||
      loading ||
      !selectedClient ||
      (petList.length === 0 && current === 1) ||
      !address
    );
  }, [clients, loading, selectedClient, petList, current]);

  const onClientSelect = (client) => {
    setSelectedClient(client);
    form.resetFields(['pets', 'concern', 'condition']);
    setPetList([]);
    setPetsToCreate([]);
  };

  const shouldShowModal = (appointmentDate, openGenerateRouteModal, vetTimezoneSlug) => {
    if (!vetTimezoneSlug) return;
    const nowInTargetTimezone = moment().tz(vetTimezoneSlug);
    function getAdjustedDayBefore(date) {
      const dayBefore = moment(date).tz(vetTimezoneSlug).subtract(1, 'days');
      if (dayBefore.isoWeekday() === 6) {
        dayBefore.subtract(1, 'days').set('hour', 23);
      } else if (dayBefore.isoWeekday() === 7) {
        dayBefore.subtract(2, 'days').set('hour', 23);
      }
      return dayBefore;
    }
    const dayBeforeDate = getAdjustedDayBefore(appointmentDate);
    const isDayBefore = nowInTargetTimezone.isSame(dayBeforeDate, 'day');
    const isAfterDayBefore18PM = nowInTargetTimezone.isAfter(
      moment(dayBeforeDate).tz(vetTimezoneSlug).hour(18).minute(0).second(0)
    );
    const isWithinTimeRange = nowInTargetTimezone.isBetween(
      moment().tz(vetTimezoneSlug).hour(14).minute(0),
      moment().tz(vetTimezoneSlug).hour(18).minute(0),
      null,
      '[)'
    );

    if (isDayBefore && isWithinTimeRange) {
      openGenerateRouteModal({ isOpen: true, shouldSendEta: false });
    } else if (isAfterDayBefore18PM) {
      openGenerateRouteModal({ isOpen: true, shouldSendEta: true });
    }
  };

  const handleSignUp = useCallback(async (values) => {
    setSubmitting(true);
    try {
      const res = await signUp({
        variables: {
          record: values
        },
        refetchQueries: ['getAppointmentsByDate', 'getTimeSlots']
      });

      if (res?.data?.signUpFlow) {
        message.success(t('new_appointment.success_message'));
        getAppointmentsByTimeslotUid(values?.timeslotId, values?.date);
        onRequestClose();
        setCurrent(0);
        shouldShowModal(
          res?.data?.signUpFlow?.appointment?.date,
          openGenerateRouteModal,
          vetTimezoneSlug
        );
      }
    } catch (err) {
      message.error(err?.message);
    }
    setSubmitting(false);
  }, []);

  const onNextClick = useCallback(() => {
    if (!isNextButtonDisabled) {
      if (current === 0) {
        setCurrent(1);
      } else {
        form.submit();
      }
    }
  }, [isNextButtonDisabled, current]);

  const onClientEdit = useCallback((e, client) => {
    e.stopPropagation();
    setClientToEdit(client);
  }, []);

  const onAddressSubmit = useCallback((client, values, address, propertyType, onRequestClose) => {
    const data = {
      email: client?.email,
      lastName: client?.lastName,
      firstName: client?.firstName,
      address: {
        ...values,
        ...address,
        propertyType,
        houseNumber: Number(address?.houseNumber)
      },
      floor: Number(values?.floor) || Number(client?.address?.floor),
      comment: values.userComment,
      apartment: values.apartment
    };
    delete data.address.country;
    delete data.address.address;
    delete data.address.__typename;
    delete data.address.userComment;
    updateUser(data, client?.uid, onRequestClose);
  }, []);

  return {
    isSubmitting,
    search,
    setSearch,
    clients,
    refetchClients: fetchPetOwners,
    loading,
    selectedClient,
    setSelectedClient,
    clientToEdit,
    setClientToEdit,
    petList,
    setPetList,
    onClientSelect,
    onClientEdit,
    isNextButtonDisabled,
    onNextClick,
    form,
    total,
    handleSignUp,
    fetchPetOwners,
    nonAppPets: petsToCreate,
    setPetsToCreate,
    onAddressSubmit
  };
};

export default useNewAppointmentModal;
