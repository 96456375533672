import React, { memo, useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { getVetTimeSlotsByUserAddressQuery, signUpFlowMutation } from 'gql';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import { Form } from 'antd';
import { useLocalizationContext } from 'common_repo';
import { BOOK, CALENDAR_STEPS } from '../../../constants/client';
import options from '../../../country-phones.json';
import { Roles, VetStatus } from '../../../constants/enums';
import AddressStep from './address/AddressStep';

import CodeVerifyModal from '../../code-verify-modal/CodeVerifyModal';
import CalendarSuccessBox from '../../calendar-success-box/CalendarSuccessBox';
import {
  serializeAddressDescription,
  signUpFinalData
} from '../../../serializers/signUpFlow.serializer';
import StepperFooter from './StepperFooter';
import Stepper from './Stepper';
import './style.scss';
import { timeSlotsToDateObject } from 'common_repo/utils/helpers';
import { PropertyType } from '../../../utils/enums';
import Modal from '../../common/modal/Modal';
import Button from '../../common/button/Button';
import Warning from 'assets/icons/warning.svg';
import { getAddressByPlaceId } from '../../../utils/helpers';
import SignInModal from '../../sign-in-modal/SignInModal';
import AuthContext from '../../../contexts/AuthContext';
import StepPersonalDetails from '../../../pages/calendar/components/StepPersonalDetails';
import ScheduleAppointmentContext from '../../../pages/calendar/ScheduleAppointmentContext';
import PetSection from '../../_new/PetSection/PetSection';
import moment from 'moment';

import { processDate, queryGetTimeSlots } from '_fsd/entities/timeslot';
import { isPetOwnerRole } from '../../../_fsd/entities';

const getSerializedAddress = (address) => ({
  city: address?.city,
  countryCode: address?.countryCode,
  description: address?.description,
  houseNumber: address?.houseNumber,
  lat: address?.lat,
  lng: address?.lng,
  placeId: address?.placeId,
  state: address?.state,
  street: address?.street,
  zipCode: address?.zipCode
});

const CalendarSteps = ({
  vet,
  sendRequestCode,
  auth,
  isBooking,
  checkExistingUser,
  isForVet,
  onFinishNewClient,
  selectedWorkingArea,
  calendarDate,
  placeId,
  selectedTimeslot,
  onRequestClose,
  goBack,
  form,
  setErrors
}) => {
  const today = dayjs().format('YYYY-MM-DD');

  const { countryCode } = useLocalizationContext();
  const history = useHistory();
  const params = useParams();
  const { user: me } = useContext(AuthContext);
  const { t } = useTranslation();

  const [address, setAddress] = useState({});
  const [current, setCurrent] = useState(0);
  const [selectedDate, _setSelectedDate] = useState(today);
  const [selectedTime, setSelectedTimeslot] = useState(null);
  const [isVerifyCodeModalOpen, setIsVerifyCodeModalOpen] = useState(false);
  const [petList, setPetList] = useState([]);
  const [petsToCreate, setPetsToCreate] = useState([]);

  useEffect(() => {
    const isPetOwner = isPetOwnerRole(me);
    if (isPetOwner) {
      form.setFieldsValue({ uid: me?.uid });
    }
  }, [me?.uid]);

  useEffect(() => {
    form.setFieldsValue({ pets: petList, petsToCreate });
  }, [petList, petsToCreate]);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSuccessMessageBoxOpen, setIsSuccessMessageBoxOpen] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [vetId, setVetId] = useState(null); // vetId of the selected timeslot
  const [propertyType, setPropertyType] = useState(PropertyType.PrivateHome);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(true);
  const [isPhoneNumberExists, setIsPhoneNumberExists] = useState(false);

  const [getTimeSlots, { data: getTimeSlotsData }] = useLazyQuery(
    isForVet ? queryGetTimeSlots : getVetTimeSlotsByUserAddressQuery,
    {
      fetchPolicy: 'cache-first'
    }
  );
  const setSelectedTimeAndVetId = (timeslot, vetId = null) => {
    setSelectedTimeslot(timeslot);
    if (vetId) {
      setVetId(vetId);
    }
  };

  const [signUp] = useMutation(signUpFlowMutation, {
    refetchQueries: ['getPetsByOwner']
  });

  const changeUrl = useCallback(
    (step) => {
      const stepsUrl = {
        0: CALENDAR_STEPS.BOOKING_PAGE,
        1: CALENDAR_STEPS.PATIENT_DETAILS,
        2: CALENDAR_STEPS.CLIENT_DETAILS,
        success: CALENDAR_STEPS.SUCCESS
      };
      if (isBooking && auth?.uid && !isForVet) {
        history.push(BOOK.replace(':step', stepsUrl[step]));
      }
    },
    [isBooking, auth, history]
  );

  const handleSubmit = async (data) => {
    if (me && me?.role === Roles.VET && !isForVet) {
      setErrorMessage(t('vet.booking.error'));
    } else {
      try {
        const res = await signUp({
          variables: {
            record: {
              ...data
            }
          }
        });

        if (res?.data?.signUpFlow) {
          if (isForVet) {
            onFinishNewClient(data?.timeSlotId, data?.date);
          } else {
            setIsSuccessMessageBoxOpen(res?.data?.signUpFlow);
            auth.setToken(res?.data?.signUpFlow?.accessToken);
          }
        }
      } catch (err) {
        setErrorMessage(err?.message);
      }
    }
  };

  const onSelect = useCallback(
    (value) => {
      let addr = {
        ...value,
        houseNumber: Number(value.houseNumber)
      };
      if (!isForVet && !placeId) {
        addr = {
          ...me?.address,
          ...addr
        };
      }
      setAddress(addr);

      form.setFieldsValue({ address: addr });
      if (!isForVet && vet?.uid && ((value?.countryCode === 'US' && value?.zipCode) || true)) {
        fetchTimeSlots(vet, addr);
      }
    },
    [selectedDate]
  );

  const fetchTimeSlots = (vet, addr, date) => {
    const record = isForVet
      ? {
          vetIds: []
        }
      : {
          uid: vet.uid,
          address: getSerializedAddress(addr),
          isForCustomers: true
        };
    const todayString = moment().format('YYYY-MM-DD');
    const startSelected = moment(date || selectedDate || new Date())
      .startOf('month')
      .format('YYYY-MM-DD');

    const start = startSelected < todayString ? todayString : startSelected;
    const end = moment(date || selectedDate || new Date())
      .endOf('month')
      .format('YYYY-MM-DD');
    getTimeSlots({
      variables: {
        record: {
          ...record,
          dateStart: start,
          dateEnd: end
        }
      }
    });
  };

  const getTime = useCallback((time) => {
    const date = dayjs()
      .set('hour', Number(time.split(':')[0]))
      .set('minute', Number(time.split(':')[1]));
    return date.format('h:mm a')?.toUpperCase();
  }, []);

  const addressError = useMemo(() => {
    if (isForVet) return !address?.lat || !address?.lng;

    return (
      !address?.houseNumber ||
      !address?.countryCode ||
      !address.description ||
      address.description.length > 150 ||
      (address?.countryCode === 'US' && !address?.zipCode)
    );
  }, [address]);

  const changeStep = useCallback(
    (step) => {
      if (step < current) setCurrent(step);
    },
    [current]
  );

  const onVerifyModalClose = useCallback(() => {
    setIsVerifyCodeModalOpen(false);
    setIsLoading(false);
    if (!isPhoneNumberExists) setCurrent(steps.length - 1);
  }, [isPhoneNumberExists]);

  const vetIsOccupied = useMemo(() => {
    return vet?.status === VetStatus.OCCUPIED.toUpperCase();
  }, [vet]);

  const dates = useMemo(() => {
    const timeSlots = getTimeSlotsData?.getTimeSlots;
    if (vet && timeSlots && timeSlots.length) {
      return timeSlotsToDateObject({
        timeslots: timeSlots,
        currenthMonth: null,
        fullTimeSlots: null,
        isForReschedule: false,
        displayLocked: false,
        startDate: dayjs(new Date()).add(1, 'day'),
        time: 14,
        isForAdminCalendar: false,
        isForVet: false,
        timeZone: vet?.timezone?.slug
      });
    }
    return {};
  }, [vet, getTimeSlotsData?.getTimeSlots]);

  const handleSendRequestCode = async (values) => {
    const codeSent = await sendRequestCode({
      phonePrefix: values.phonePrefix,
      phoneNumber: values.phoneNumber,
      role: Roles.PET_OWNER
    });
    if (codeSent?.data?.requestCode) {
      setIsVerifyCodeModalOpen(true);
      setFormValues(values);
    } else {
      setIsLoading(false);
      setErrorMessage(codeSent?.message);
    }
  };

  const setSelectedDate = (date) => {
    _setSelectedDate(date);
    if (vet && address && !isForVet) {
      fetchTimeSlots(vet, getSerializedAddress(address), date);
    }
  };

  const steps = [
    {
      title: 'personal.details',
      content: <StepPersonalDetails isForVet={isForVet} form={form} />
    },
    {
      title: 'address.and.date.time',
      content: (
        <AddressStep
          placeId={placeId}
          onSelect={onSelect}
          calendarIsDisabled={addressError || vetIsOccupied}
          form={form}
          address={address}
          addressError={addressError}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedTime={selectedTime}
          setSelectedTimeAndVetId={setSelectedTimeAndVetId} // when selecting a timeslot of another vet in multi-vet practice
          vet={vet} // for auto selection when on vet's dashboard etc
          dates={dates}
          timeSlots={getTimeSlotsData?.getTimeSlots}
          getTime={getTime}
          vetIsOccupied={vetIsOccupied}
          auth={auth}
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          isForVet={isForVet}
        />
      )
    },
    {
      title: 'patient.details',
      content: (
        <PetSection
          setPetsToCreate={setPetsToCreate}
          petList={petList}
          setPetList={setPetList}
          vet={selectedTime?.vet || vet}
          client={auth}
          isVet={isForVet}
        />
      )
    }
  ];

  const initialValues = {
    pets: [],
    phonePrefix: options.find((o) => o.code === (countryCode || 'US'))?.dial_code || '',
    phoneNumber: ''
  };

  useEffect(() => {
    if (placeId) {
      const getNewAddress = (addr) => {
        form.setFieldsValue({ address: addr?.description });
        setAddress(addr);
        fetchTimeSlots(vet, getSerializedAddress(addr));
      };

      getAddressByPlaceId(placeId, getNewAddress);
    } else if (me?.address && !isForVet) {
      form.setFieldsValue({
        floor: me?.address?.floor ? String(me?.address?.floor) : '',
        apartment: me?.address?.apartment,
        comment: me?.address?.comment,
        userComment: me?.address?.comment
      });
      const addr = {
        ...me?.address,
        countryCode: me?.address?.country?.code
      };
      setAddress(addr);
      fetchTimeSlots(vet, getSerializedAddress(addr));
    }
  }, [me, placeId]);

  // submit data
  // todo: auth and me - is the same things
  useEffect(async () => {
    if (isVerificationCodeSent && !isPhoneNumberExists) {
      setSubmitting(true);
      const signUpObject = signUpFinalData(
        formValues,
        address,
        isForVet ? null : me,
        auth,
        selectedTime,
        petList,
        propertyType,
        isForVet,
        selectedTimeslot,
        selectedTimeslot?.vet || vet,
        petsToCreate,
        true
      );

      if (signUpObject.user.address?.country) {
        delete signUpObject.user.address?.country;
      }
      if (signUpObject.user.address?.__typename) {
        delete signUpObject.user.address?.__typename;
      }

      await handleSubmit(signUpObject).finally(() => setSubmitting(false));
      setIsVerificationCodeSent(false);
    }
  }, [isVerificationCodeSent]);

  // reset error message
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000); // 5sec
    }
  }, [errorMessage]);

  useEffect(() => {
    if (params?.step) {
      history.push(BOOK.replace(':step', ''));
    }
  }, []);
  // if modal is opened from vet's side, pass the selected timeslot and date
  useEffect(() => {
    if (selectedWorkingArea && !selectedTime) {
      setSelectedTimeAndVetId(selectedWorkingArea, vet.uid);
    }
  }, [selectedWorkingArea]);

  useEffect(() => {
    if (vet?.uid) {
      setVetId(vet.uid);
    }
  }, [vet]);

  useEffect(() => {
    if (!isForVet && me?.firstName && me?.lastName && me?.email) {
      setCurrent(current + 1);
      changeUrl(current + 1);
    }
  }, [isForVet, me]);

  const isSchedule = history.location.pathname.includes('schedule');
  return (
    <>
      <div className={`vet-calendar-steps ${isBooking || isSchedule ? 'booking-page' : 'modal'}`}>
        <Stepper
          current={current}
          changeStep={changeStep}
          me={me}
          isForVet={isForVet}
          onRequestClose={onRequestClose}
        />

        <Form
          className="form"
          form={form}
          layout="vertical"
          disabled={isSubmitting}
          initialValues={initialValues}
          onFinish={async () => {
            if (current === 0) {
              const email = form.getFieldValue('email') || '';
              const phonePrefix = form.getFieldValue('phonePrefix') || '';
              const phoneNumber = form.getFieldValue('phoneNumber') || '';
              if (isForVet || (!auth.email && auth.email !== email)) {
                checkExistingUser({
                  variables: {
                    record: {
                      email,
                      phonePrefix,
                      phoneNumber
                    }
                  }
                }).then((data) => {
                  const { checkIfUserExists } = data.data || {};
                  if (checkIfUserExists?.email) {
                    if (!isForVet && checkIfUserExists?.role === Roles.PET_OWNER) {
                      setErrors &&
                        setErrors({
                          canBeReassigned: true,
                          email: 'This email is already in use, try different one or'
                        });
                    } else {
                      form.setFields([
                        {
                          name: 'email',
                          errors: [t('error.email.already.exists')]
                        }
                      ]);
                      setErrors &&
                        setErrors({
                          canBeReassigned: false,
                          email: 'This email is already in use, try different one'
                        });
                    }
                  }
                  if (isForVet && checkIfUserExists?.phoneNumber) {
                    form.setFields([
                      {
                        name: 'phoneNumber',
                        errors: [t('error.phone.already.exists')]
                      }
                    ]);
                    setIsPhoneNumberExists(true);
                  }
                  if (!isForVet && !checkIfUserExists?.email) {
                    setCurrent(current + 1);
                    changeUrl(current + 1);
                    setErrors && setErrors({});
                  }
                  if (isForVet && !checkIfUserExists?.phoneNumber) {
                    setCurrent(current + 1);
                    changeUrl(current + 1);
                    setErrors && setErrors({});
                  }
                });
              } else {
                setCurrent(current + 1);
                changeUrl(current + 1);
              }
            }
            if (current === 1) {
              setCurrent(current + 1);
              changeUrl(current + 1);
            }
            if (current === 2) {
              setIsLoading(true);
              const values = form.getFieldsValue(true);
              values.date = processDate(selectedTime.date);
              setFormValues(values);
              // trigger for submit
              setIsVerificationCodeSent(true);
              changeUrl('success');
            }
          }}>
          {steps[current].content}
          <StepperFooter
            current={current}
            phoneNumberError={phoneNumberError}
            setPhoneNumberError={setPhoneNumberError}
            addressError={addressError}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            errorMessage={errorMessage}
            steps={steps}
            changeStep={changeStep}
            form={form}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            auth={auth}
            isBooking={isBooking}
            vetIsOccupied={vetIsOccupied}
            ignoreFirstStep={propertyType === PropertyType.PrivateHome}
            isForVet={isForVet}
            goBack={goBack}
          />
        </Form>
      </div>
      {isSuccessMessageBoxOpen && (
        <CalendarSuccessBox
          data={isSuccessMessageBoxOpen}
          isBooking={isBooking}
          getTime={getTime}
        />
      )}

      {isVerifyCodeModalOpen && (
        <CodeVerifyModal
          isOpen={true}
          phonePrefix={form.getFieldValue('phonePrefix')}
          phoneNumber={form.getFieldValue('phoneNumber')}
          onRequestClose={onVerifyModalClose}
          setIsVerificationCodeSent={setIsVerificationCodeSent}
          isPhoneNumberExists={isPhoneNumberExists}
        />
      )}

      {isNoteModalOpen && !isForVet && (
        <Modal isOpen={true} onRequestClose={() => setIsNoteModalOpen(false)}>
          <div className="note-modal">
            <div className="d-flex">
              <img src={Warning} alt="" />
              <h3>{t('note_modal.title')}</h3>
            </div>
            <p>{t('note_modal.text')}</p>
            <div className="text-right">
              <Button
                label={t('note_modal.button')}
                color="darkGreen"
                onClick={() => setIsNoteModalOpen(false)}
              />
            </div>
          </div>
        </Modal>
      )}

      {isPhoneNumberExists && (
        <SignInModal
          onRequestClose={() => setIsPhoneNumberExists(false)}
          values={isPhoneNumberExists}
          handleSendRequestCode={handleSendRequestCode}
        />
      )}
    </>
  );
};

export const MemoizedCalendarSteps = memo(CalendarSteps);

const Container = (props) => {
  const { form, checkIfUserExists, setErrors, errors, isSubmitting } = useContext(
    ScheduleAppointmentContext
  );
  return (
    <MemoizedCalendarSteps
      {...props}
      setErrors={setErrors}
      errors={errors}
      form={form}
      isSubmitting={isSubmitting}
      checkExistingUser={checkIfUserExists}
    />
  );
};

export default Container;
